import React, { useState } from 'react';
import { Line, Bar, Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Card,
  ButtonBase,
} from "@material-ui/core";
import {  makeStyles } from "@material-ui/core/styles";



ChartJS.register(...registerables);

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
      width: "100%",
    },
   faultCard:{
    width:"100%",
    height:"100%"
   }
  }))

const FaultTrendAndHistogram = ({ trendData, histogramData }) => {
  const [selectedDevice, setSelectedDevice] = useState(trendData[0].Devicename);

  // Function to get border color based on fault type
  const getBorderColor = (type) => {
    const colors = {
      open: 'rgba(255, 99, 132, 1)',  // Red for open faults
      close: 'rgba(54, 162, 235, 1)', // Blue for closed faults
    };
    return colors[type];
  };

  // Prepare data for the trend chart
  const trendDatasets = trendData
    .filter(device => device.Devicename === selectedDevice)
    .map(device => ({
      label: device.Devicename,
      data: device.counts,
      fill: false,
      borderColor: getBorderColor('open'),
    }));

  const openCounts = {};
  const closeCounts = {};

  histogramData.forEach(entry => {
    if (entry.open_count !== undefined) {
      openCounts[entry.Devicename] = entry.open_count;
    }
    if (entry.close_count !== undefined) {
      closeCounts[entry.Devicename] = entry.close_count;
    }
  });

  const histogramLabels = Object.keys(openCounts);

  const histogramDataConfig = {
    labels: histogramLabels,
    datasets: [
      {
        label: 'Open Faults',
        backgroundColor: getBorderColor('open'),
        data: Object.values(openCounts),
      },
      {
        label: 'Closed Faults',
        backgroundColor: getBorderColor('close'),
        data: Object.values(closeCounts),
      },
    ],
  };

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  return (
    <div>
      <div>
        {/* <label htmlFor="deviceSelect">Select Device: </label> */}
        {/* <select id="deviceSelect" value={selectedDevice} onChange={handleDeviceChange}>
          {trendData.map(device => (
            <option key={device.Devicename} value={device.Devicename}>
              {device.Devicename}
            </option>
          ))}
        </select> */}
      </div>
      <div style={{ display: 'flex' }}>
        {/* <div style={{ flex: 1 }}>
          <h2>Fault Counts Trend Over Time</h2>
          <Line data={trendDataConfig} height={200} width={400} />
        </div> */}
        <div style={{ flex: 1 }}>
          <h2>Fault Stats</h2>
          <Bar data={histogramDataConfig} height={200} width={400} />
        </div>
      </div>
      {/* <div style={{ marginTop: '20px' }}>
        <h2>Correlation Heatmap</h2>
        <Scatter 
          data={{
            datasets: correlationMatrix.map((row, i) => ({
              label: correlationLabels[i],
              data: row.map((value, j) => ({ x: j, y: i, v: value })),
              backgroundColor: row.map(value => `rgba(54, 162, 235, ${Math.abs(value)})`),
            })),
          }}
          options={{
            scales: {
              x: {
                type: 'linear',
                position: 'bottom',
                min: 0,
                max: correlationLabels.length - 1,
                ticks: {
                  callback: function(value) {
                    return correlationLabels[value];
                  },
                },
                title: {
                  display: true,
                  text: 'Devices',
                },
              },
              y: {
                type: 'linear',
                min: 0,
                max: correlationLabels.length - 1,
                ticks: {
                  callback: function(value) {
                    return correlationLabels[value];
                  },
                },
                title: {
                  display: true,
                  text: 'Devices',
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return `Correlation: ${context.raw.v.toFixed(2)}`;
                  },
                },
              },
            },
          }}
          height={400}
          width={400}
        />
      </div> */}
    </div>
  );
};

const App = () => {
  const classes = useStyles();
  // Sample trend data
  const trendData = [
    {
      Devicename: "AHU-1",
      timestamps: ['2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01'],
      counts: [1, 2, 4, 3, 5],
    },
    {
      Devicename: "AHU-2",
      timestamps: ['2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01'],
      counts: [2, 5, 7, 3, 9],
    },
    {
      Devicename: "AHU-3",
      timestamps: ['2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01'],
      counts: [6, 2, 9, 8, 10],
    },
    {
      Devicename: "Chiller-1",
      timestamps: ['2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01'],
      counts: [6, 2, 9, 8, 10],
    },
    {
      Devicename: "Chiller-2",
      timestamps: ['2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01'],
      counts: [2, 1, 7, 4, 1],
    },
    {
      Devicename: "Chiller-3",
      timestamps: ['2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01'],
      counts: [3, 6, 4, 3, 2],
    },
    {
      Devicename: "Chiller-4",
      timestamps: ['2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01'],
      counts: [3, 6, 4, 3, 2],
    },
    {
      Devicename: "Chiller-5",
      timestamps: ['2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01'],
      counts: [1, 9, 2, 6, 2],
    },
  ];

  // Sample histogram data
  const histogramData = [
    {
      "Devicename": "AHU-1",
      "open_count": 1,
      "close_count": 2
    },
    {
      "Devicename": "AHU-2",
      "open_count": 2,
      "close_count": 5
    },
    {
      "Devicename": "AHU-3",
      "open_count": 4,
      "close_count": 7
    },
    {
      "Devicename": "Chiller-1",
      "open_count": 3,
      "close_count": 6
    },
    {
      "Devicename": "Chiller-2",
      "open_count": 5,
      "close_count": 2
    },
    {
      "Devicename": "Chiller-3",
      "open_count": 3,
      "close_count": 4
    },
  ];

  return (
    <div>
        <Grid container xs={12}>
        <Card className={classes.faultCard}>
      <FaultTrendAndHistogram trendData={trendData} histogramData={histogramData} />
      </Card>
      </Grid>
    </div>
  );
};

export default App;
