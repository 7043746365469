
import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import {  makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Card,
  ButtonBase,
} from "@material-ui/core";
import pumpimg from './../../assets/img/PrimaryPump.png';
import SwitchSelector from "react-switch-selector";
import api from "../../api";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import value from "views/Custom/value";
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import { SemanticToastContainer, toast } from "react-semantic-toasts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  paper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:'0px 8px 40px #0123B433;',
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
     borderRadius: '12px',
     opacity:'1'
  },
  paper1:{
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:'0px 0px 10px #0123B421',
    opacity:'1',
    borderRadius:'12px',
    height:'14vh',
    // display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControl: {
    autosize: true,
    clearable: false,
  }, 
  CardbodyInsideGrid: {
    "justify-content": "center",
    display: "inline-flex",
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
  },
  status:{
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    height: '100%' 
  },
  cardHeading:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    whiteSpace:"nowrap",
    color:"#000000",
    marginTop:'1vh',
    font:'normal normal medium 17px/60px Bw Seido Round',
    opacity:'1',
    fontWeight:'bold'
  },
  switchselector:{
    display:'flex',
    justifyContent:'center',
    width:"16vh",
    marginTop:'1vh',
  },
  semicircleBar:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    marginTop:'-0.8vh'
  },
  cardbody:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    fontSize:"4.5vh",
    fontWeight:'bold',
    opacity:'1',
    color:'#0123B4'
  }
}));

function GlPump(props) {
  const classes = useStyles();
  const [floor, setFloor] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const [allPumpsData, setAllPumpsData] = useState([]);
  const [eachPumpsData, setEachPumpsData] = useState({});
  const [runHours, setRunHours] = useState("");
  const [pumpOn_Off, setPumpOn_Off] = useState(0);
  const [pumpStatusColor, setPumpStatusColor] = useState(0);
  const [click, setClick] = useState("");
  const [openerr,setOpenerr] = React.useState(false);
  const [errmsg,setErrmsg] = React.useState('');
  const CPM_Status = localStorage.getItem("CPM_AM_Status");
  const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  const options1 = [
    {
      label: "Card View",
      value: "cardview",
      selectedBackgroundColor: "#0123b4",
    },
    {
      label: "Table View",
      value: "tableview",
      selectedBackgroundColor: "#0123b4",
    },
  ];
  const options = [
    {
      label: "OFF",
      value: 0,
      selectedBackgroundColor: "#0123B4",
      fontSize:"12"

    },
    {
      label: "ON",
      value: 1,
      selectedBackgroundColor: "#34C759",
      fontSize:"12"
    },
  ];
//   const initialSelectedIndex = options.findIndex(
//     ({ value }) => value === "active"
//   );
  const initialSelectedIndex1 = options1.findIndex(
    ({ value }) => value === "manual"
  );

  // const onClickIssue = () => {};
  useEffect(() => {
        if(props){
            console.log("propsssss",props)
                  if(props.location !== undefined){
                        if(props.location.state.click == 'heading'){
                              setClick('heading')
                              setAllPumpsData(props.location.state.data)
                        }
                  }else{
                              setClick('eachPump')
                              setEachPumpsData(props.data)
                              if(props.type == 'PrimaryPump'){
                                    if(props.data.Eqp_Metrics){
                                          setRunHours(props.data.Eqp_Metrics.rh_cumulative)
                                    }
                                    if(props.data.Eqp_Attributes){
                                          setPumpOn_Off((props.data["Eqp_Attributes"]["Pri_Pmp_On_Off"].presentValue)=="active"? 1:0)
                                    }
                                    if(props.data.Eqp_Attributes.Pri_Pmp_On_Off != undefined){
                                          setPumpStatusColor((props.data["Eqp_Attributes"]["Pri_Pmp_On_Off"]))
                                    }
                              }else if(props.type == 'SecondaryPump'){
                                    if(props.data.Eqp_Metrics){
                                          setRunHours(props.data.Eqp_Metrics.rh_cumulative)
                                    }
                                    if(props.data.Eqp_Attributes){
                                          setPumpOn_Off((props.data.Eqp_Attributes.Sec_Pmp_SS.presentValue) =="active"? 1:0)
                                    }
                                    if(props.data.Eqp_Attributes.Sec_Pmp_SS != undefined){
                                          setPumpStatusColor((props.data["Eqp_Attributes"]["Sec_Pmp_SS"]))
                                    }
                              }
                              else if(props.type == 'CondenserPump'){
                                    if(props.data.Eqp_Metrics){
                                          setRunHours(props.data.Eqp_Metrics.rh_cumulative)
                                    }
                                    if(props.data.Eqp_Attributes){
                                          setPumpOn_Off((props.data.Eqp_Attributes.Cnd_Pmp_SS.presentValue) =="active"? 1:0)
                                    }
                                    if(props.data.Eqp_Attributes.Cnd_Pmp_SS != undefined){
                                          setPumpStatusColor((props.data["Eqp_Attributes"]["Cnd_Pmp_SS"]))
                                    }
                              }
                  }
        }
  }, []);

  const onChange = (newValue) => {
        setPumpOn_Off(newValue)
        let id= props.data.id;
        let action= props.type == "PrimaryPump"?( newValue == 0? "TURN_OFF_PRIMARY_PUMP":"TURN_ON_PRIMARY_PUMP"): props.type == "SecondaryPump"? ( newValue == 0? "TURN_OFF_SECONDARY_PUMP":"TURN_ON_SECONDARY_PUMP"): ( newValue == 0? "TURN_OFF_CONDENSER_PUMP":"TURN_ON_CONDENSER_PUMP");
        let ss_type= props.type == "PrimaryPump"? "NONGL_SS_PUMPS": props.type == "SecondaryPump"? "'NONGL_SS_SECONDARY_PUMPS": "NONGL_SS_CONDENSER_PUMPS";
        let req={
            id,
            action,
            ss_type
        }
        api.floor.cpmOnOffControl(req).then((response)=>{

            // setOpenerr(true)
            // setErrmsg(response)
            setPumpOn_Off(response.startsWith('Working with a Scenario')?(newValue == 0?1:0):newValue)
            toast({
                  type: response.startsWith('Working with a Scenario')?"error":"success",
                  icon: response.startsWith('Working with a Scenario')?"exclamation triangle":"check circle",
                  title: response.startsWith('Working with a Scenario')?"Error":"Success",
                  description: response,
                  time: 2000,
                })
        })
        .catch((err)=>{
            setOpenerr(true)
            setErrmsg(err)          
        })
      };

      const handleerrorclose = () => {
            setOpenerr(false);
            setErrmsg('');
          };

      return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
      <Snackbar open={openerr} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert style={{ cursor: "pointer" }} severity="error" variant="filled" onClose={handleerrorclose}>
          {errmsg}
        </Alert>
      </Snackbar>
        <Grid container spacing={2}>
            {/* <div style={{"overflow-x":"hidden","overflow-y": "scroll",scrollBehavior:"smooth",height:"82vh",marginTop:"1vh"}}> */}
            <Grid container spacing={2}>
                  { 
                  click == 'heading' ? <>
                  { allPumpsData.map((response) =>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>{response.name}</div></Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                          <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh',pointerEvents:CPM_Status == "true"?"none":"", opacity:CPM_Status == "true"?"0.4":""}}>
                                          <SwitchSelector
                                          style={{ borderRadius: "12px" }}
                                          onChange={onChange}
                                          options={options}
                                          // initialSelectedIndex={initialSelectedIndex}
                                          // border="1px solid #0123B4"
                                          backgroundColor={"#e9e5e5"}
                                          fontColor={"rgba(0, 0, 0, 0.87)"}
                                          // wrapperBorderRadius={true}
                                          optionBorderRadius={5}
                                          wrapperBorderRadius={5} />
                                          </div>
                                    </Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#68DBFF', color:'#0123B4', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                      <div>OFF</div>
                                                      </Grid>
                                                </Grid>
                                                </Card>
                                    </Grid>
                              </Grid>
                              </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                              <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        {/* 1350 */}N/A
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                        Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        {/* 0  */}N/A
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        {/* 0 */}N/A
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                        </Card>
                        </Grid>
                   )}
                   </>
                  :         
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>{eachPumpsData.name}</div></Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                            <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh',pointerEvents:CPM_Status == "true"?"none":"", opacity:CPM_Status == "true"?"0.4":""}}>
                                                            <SwitchSelector
                                                                  style={{ borderRadius: "12px" }}
                                                                  onChange={onChange}
                                                                  options={options}
                                                                  forcedSelectedIndex={pumpOn_Off}
                                                                  // border="1px solid #0123B4"
                                                                  backgroundColor={"#e9e5e5"}
                                                                  fontColor={"rgba(0, 0, 0, 0.87)"}
                                                                  // wrapperBorderRadius={true}
                                                                  optionBorderRadius={5}
                                                                  wrapperBorderRadius={5} />
                                                                  </div>
                                    </Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                {/* <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#FED0C1', color:'#FC6434', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                      <Grid container xs={12} style={{ height: '100%' }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                            <div>Fault</div>
                                                            </Grid>
                                                      </Grid>
                                                </Card> */}
                                                 {/* <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#68DBFF', color:'#0123B4', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                      <Grid container xs={12} style={{ height: '100%' }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                            <div>OFF</div>bg->#C1EECD-for on,color->#34C759
                                                            </Grid>
                                                      </Grid>
                                                </Card> */}
                                                <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor:(pumpStatusColor["presentValue"] == ("inactive"||0))?"#68DBFF":(pumpStatusColor["presentValue"] == ("active"||1))?"#C1EECD":eachPumpsData.alerts_cri>0?"#FED0C1":eachPumpsData.alerts_solu>0?"#FFF36D":"grey", 
                                                color:(pumpStatusColor["presentValue"] == ("inactive"||0))?"#0123B4":(pumpStatusColor["presentValue"] == ("active"||1))?"#34C759":eachPumpsData.alerts_cri>0?"#FC6434":eachPumpsData.alerts_solu>0?"#f2aa1a":"black", textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                      <Grid container xs={12} style={{ height: '100%' }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                            {/* <div>{(pumpStatusColor["presentValue"] == ("inactive"||0))?"grey":(pumpStatusColor["presentValue"] == ("active"||1))?"#21ba45":pumpStatusColor.alerts_cri>0?"#CE1E1E":pumpStatusColor.alerts_solu>0?"#f2aa1a":"grey"}</div> */}
                                                            <div>{(pumpStatusColor["presentValue"] == ("inactive"||0))?"OFF":(pumpStatusColor["presentValue"] == ("active"||1))?"ON"
                                                            :eachPumpsData.alerts_cri>0 || eachPumpsData.alerts_solu>0?"Fault"
                                                            :"-"}</div>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                    </Grid>
                              </Grid>
                              </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                  {runHours?formatter.format((formatter.format(runHours)/60))+'Hrs':'N/A'}
                                                                        {/* {runHours?(runHours/60)+'Hrs':'N/A'} */}
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        {/* 1350 */}N/A
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                  Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        {/* 0  */}N/A
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        {/* 0 */}N/A
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                        </Card>
                   </Grid>
                  }
                
                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>Jahn</div></Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{textDecorationLine:"underline",color:'#0123b4',fontWeight:'bold',fontSize:'2vh',whiteSpace:'nowrap'}}>3F-PH1-Pump-2</div></Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                        <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh'}}>
                                                      <SwitchSelector
                                                          style={{ borderRadius: "12px" }}
                                                          onChange={onChange}
                                                          options={options}
                                                          initialSelectedIndex={initialSelectedIndex}
                                                          // border="1px solid #0123B4"
                                                          backgroundColor={"#e9e5e5"}
                                                          fontColor={"rgba(0, 0, 0, 0.87)"}
                                                          // wrapperBorderRadius={true}
                                                          optionBorderRadius={5}
                                                          wrapperBorderRadius={5} />
                                                        </div>
                                                      </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                              <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#68DBFF', color:'#0123B4', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                    <div>OFF</div>
                                                  </Grid>
                                                </Grid>
                                              </Card>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                              <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        1350
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                   Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0 
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>Pump-3</div></Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{textDecorationLine:"underline",color:'#0123b4',fontWeight:'bold',fontSize:'2vh',whiteSpace:'nowrap'}}>3F-PH1-Pump-3</div></Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                        <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh'}}>
                                                      <SwitchSelector
                                                          style={{ borderRadius: "12px" }}
                                                          onChange={onChange}
                                                          options={options}
                                                          initialSelectedIndex={initialSelectedIndex}
                                                          // border="1px solid #0123B4"
                                                          backgroundColor={"#e9e5e5"}
                                                          fontColor={"rgba(0, 0, 0, 0.87)"}
                                                          // wrapperBorderRadius={true}
                                                          optionBorderRadius={5}
                                                          wrapperBorderRadius={5} />
                                                        </div>
                                                      </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                              <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#C1EECD', color:'#34C759', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                    <div>ON</div>
                                                  </Grid>
                                                </Grid>
                                              </Card>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                              <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        1350
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                   Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0 
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>Pump-4</div></Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{textDecorationLine:"underline",color:'#0123b4',fontWeight:'bold',fontSize:'2vh',whiteSpace:'nowrap'}}>3F-PH1-Pump-4</div></Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                        <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh'}}>
                                                      <SwitchSelector
                                                          style={{ borderRadius: "12px" }}
                                                          onChange={onChange}
                                                          options={options}
                                                          initialSelectedIndex={initialSelectedIndex}
                                                          // border="1px solid #0123B4"
                                                          backgroundColor={"#e9e5e5"}
                                                          fontColor={"rgba(0, 0, 0, 0.87)"}
                                                          // wrapperBorderRadius={true}
                                                          optionBorderRadius={5}
                                                          wrapperBorderRadius={5} />
                                                        </div>
                                                      </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                              <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#C1EECD', color:'#34C759', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                    <div>ON</div>
                                                  </Grid>
                                                </Grid>
                                              </Card>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                              <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        1350
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                   Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0 kPa
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid> */}
            </Grid>
            {/* </div> */}
        </Grid>
        <SemanticToastContainer position="top-center" />  
    </div>
  );
}

export default GlPump;

