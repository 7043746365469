import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import api from './../../api';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import Paper from '@material-ui/core/Paper';
import { Grid, Typography, Card, ButtonBase, Divider } from '@material-ui/core';
import { Box } from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import { Map, ImageOverlay, Marker, Tooltip, ZoomControl } from 'react-leaflet';
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import Select from "@material-ui/core/Select";
import {
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import image from './../../assets/img/CT.png'
import Success from 'components/Typography/Success';
import Warning from "components/Typography/Warning";
import Danger from "components/Typography/Danger";
import TimeS from "./../TimeS";
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import SwitchSelector from "react-switch-selector";
import ChillerReports from "./glChillerReports";
import PumpPage from "./GlPrimaryPump";
import CreateIcon from '@material-ui/icons/Create';
import LandingPage from './../../views/Heatmap/upsEmsLanding';
import Tooltip1 from '@material-ui/core/Tooltip';
import { element } from 'prop-types';

const Leaflet = require('leaflet');

const StyledTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "#FEE8DA",
    // backgroundColor: "red",
    fontSize:"12px"
  }
})(Tooltip1);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  faults_paper: {
    // padding: theme.spacing(1),
    // borderRadius:"37px",
    color:"white",
    display:'flex',
    textAlign:"center",
    alignItems: 'center',
     justify: 'center',
    //  height:'3.5vh',
     backgroundColor: 'blue',
    // [theme.breakpoints.down('sm')]: {
    //   width:"7.5vh"
    // },
    // [theme.breakpoints.up('md')]: {
    //   width:"6vh"
    // },
    // [theme.breakpoints.up('lg')]: {
    //   width:"7.5vh"
    // },
    // [theme.breakpoints.up('xl')]: {
    //   width:"7.5vh"
    // },
  },
});

const processDataFromJson = false;

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  customDialogPump: {
    cursor:"pointer",
    // Set the desired width for the dialog
    height: '320px', // Adjust this value as needed
    width: '700px', // Adjust this value as needed
  },
  customDialog: {
    cursor:"pointer",
    // Set the desired width for the dialog
    height: '480px', // Adjust this value as needed
    width: '700px', // Adjust this value as needed
  },
  root: {
    flexGrow: 1,
    marginTop:"-3vh"
  },
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  }, papereg: {
    width:"5vh",
    height: '2vh',
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0 4px 10px 5px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    borderRadius: '6px',
    height: '50vh',
    marginTop:"1vh"
  },
  alertspaper: {
    background:"#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // backgroundColor: 'white',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#Fefefa",
    // opacity:"1",
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    borderRadius: '6px',
    height: '16vh',
    marginTop:"1vh"
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  childpaper: {
    background:"#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // backgroundColor: 'white',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#Fefefa",
    // opacity:"1",
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    borderRadius: '6px',
  },
  graphpaper: {
    height: "25.9vh",
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    background:"#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // backgroundColor: 'white',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#Fefefa",
    // opacity:"1",
    borderRadius: '6px',
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor:"#0123b4",borderRadius:"8px"
    },
    "& .MuiSelect-root ": {
      marginTop:"-2vh"
    }
  },
  imagecard:{
    height: "63vh",
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    background:"#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // backgroundColor: 'white',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#Fefefa",
    // opacity:"1",
    borderRadius: '6px',
  },
  text: {
    fontSize: "14px",
    color: " #292929",
    fontFamily: "Arial"
  },
  control1: {
    width: "6vh",
    marginTop: "0vh",
    marginLeft: "-11vh",
    fontFamily: "Arial"
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  datacards: {
    width: '100%',
    height: '13vh',
    margin: '1%',
    borderRadius: '0.8vw',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#Fefefa",   
  },
  semicircularbar:{
    height:"11vh",
    background:"#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#Fefefa",
    // opacity:"1",
    borderRadius: '6px',
  },
  semicircularbarcomp:{
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
        marginTop:'-0.5vh',
        marginLeft:'-1.5vh'
      },
      '@media (min-width:600px) and (max-width:959.95px)': {//sm
        marginTop:'1vh',
        marginLeft:'-0.1vh'
      },
      '@media (min-width:960px) and (max-width:1279.95px)': {//md
        marginTop:'-1vh',
        marginLeft:'-1.1vh'
      },
      '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
        marginTop:'0vh',
        marginLeft:'-0.1vh'
      },
      '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
        marginTop:'1vh',
        marginLeft:'0.5vh'
      },
  },
  buttons: {
    font:'normal normal medium 16px/17px Bw Seido Round',
    color:'#FFFFFF',
    opacity:'1',
    width: '130%',
    height: '3.2vh',
    border: "none",
    cursor: "pointer",
    fontSize: "2vh",
    background:'#CE1E1E 0% 0% no-repeat padding-box;',
    borderRadius: "8px",
  },
  emptybuttons: {
    font:'normal normal medium 16px/17px Bw Seido Round',
    color:'grey',
    opacity:'1',
    width: '130%',
    height: '3.2vh',
    border: "none",
    cursor: "pointer",
    fontSize: "2vh",
    background:'rgb(239 229 229 / 87%)',
    borderRadius: "8px",
  },
  smallbuttons: {
    width: '29%',
    height: '3.2vh',
    backgroundColor: "rgb(239 229 229 / 87%)",
    border: "none",
    cursor: "pointer",
    fontSize: "2vh",
    borderRadius: "0.4vw",
    margin: '2%'
  },
  CardHeadFont:{
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      fontSize: "1.5vh",
      },
      '@media (min-width:600px) and (max-width:959.95px)': {//sm
        fontSize: "1.9vh",
        borderRadius:'10px'
      },
      '@media (min-width:960px) and (max-width:1279.95px)': {//md
        fontSize: "1.4vh",
      },
      '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
        fontSize: "1.6vh",
      },
      '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
        fontSize: "1.7vh",
      },
  },
}));

function getJSONElement(myJson, elementPath = []) {
  let eValue = myJson;
  for (let i = 0; i < elementPath.length; i++) {
    if (eValue !== undefined && eValue !== null) {
      eValue = eValue[elementPath[i]];

      // Check if the value is the string "NULL" and return null
      if (typeof eValue === 'string' && eValue.toUpperCase() === 'NULL') {
        return null;
      }
    } else {
      eValue = undefined;
      console.log(`Unable to process JSON: ${elementPath}`);
      break;
    }
  }
  return eValue !== undefined ? eValue : null;
}

export default function App(props) {
  const classes = useStyles();
  const mapRef = React.createRef()
  const alerts = useSelector(state => state.alarm.alarmData)
  const [eachChillerData, setEachChillerData] = React.useState([])
  const [criticalAlertsChiller, setcriticalAlertsChiller] = React.useState(0);
  const [soluAlertsChiller, setsoluAlertsChiller] = React.useState(0);
  const [criticalAlertsCoolT, setcriticalAlertsCoolT] = React.useState(0);
  const [soluAlertsCoolT, setsoluAlertsCoolT] = React.useState(0);
  const [criticalAlertsPP, setcriticalAlertsPP] = React.useState(0);
  const [soluAlertsPP, setsoluAlertsPP] = React.useState(0);
  const [criticalAlertsSP, setcriticalAlertsSP] = React.useState(0);
  const [soluAlertsSP, setsoluAlertsSP] = React.useState(0);
  const [criticalAlertsCondenser, setcriticalAlertsCondenser] = React.useState(0);
  const [soluAlertsCondenser, setsoluAlertsCondenser] = React.useState(0);
  const [openerr,setOpenerr] = React.useState(false);
  const [errmsg,setErrmsg] = React.useState('');
  const [CWH_ST, setCWH_ST] = useState([]);
  const [CWH_RT, setCWH_RT] = useState([]);
  const [CndW_HST, setCndW_HST] = useState([]);
  const [CndW_HRT, setCndW_HRT] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [pumpModal, setPumpModal] = useState(false);
  const [triggerModal, setTriggerModal] = useState(false);
  const [primaryPump, setPrimaryPump] = useState([]);
  const [pripAlerts, setPriPAlerts] = useState([]);
  const [secondaryPump, setSecondaryPump] = useState([]);
  const [secpAlerts, setSecPAlerts] = useState([]);
  const [coolingTowers, setCoolingTowers] = useState([]);
  const [ctAlerts, setCTAlerts] = useState([]);
  const [condensers, setCondensers] = useState([]);
  const [condenserAlerts, setCondenserAlerts] = useState([]);
  const [pumpType, setPumpType] = useState("");
  const [pumpData, setPumpData] = useState({});
  const [modalHeading, setModalHeading] = useState('Parameter');
  const [isHovered, setIsHovered] = useState(false);
  const [cpmres, setCPMRes] = useState('');
  const [cpmOnOffStatus, setCPMOnOffStatus] = useState(0);
  const [cpmCurrentStatedata, setCPMCurrentStateData] = useState({});
  let chillers= processDataFromJson == true ?  eachChillerData:Object.values(eachChillerData).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
  let coolingT= processDataFromJson == true ?  coolingTowers:Object.values(coolingTowers).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
  let priPump= processDataFromJson == true ?  primaryPump:Object.values(primaryPump).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
  let secPump= processDataFromJson == true ?  secondaryPump:Object.values(secondaryPump).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
  let condenserPump= processDataFromJson == true ?  condensers:Object.values(condensers).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});

  const options = [
    {
      selectedFontColor: "white",
      label: "Manual",
      value: 0,
      selectedBackgroundColor: "green",
    },
    {
      selectedFontColor: "white",
      label: "Auto",
      value: 1,
      selectedBackgroundColor: "#0123B4",
    },
    // {
    //   selectedFontColor: "white",
    //   label: "Off",
    //   value: 1,
    //   selectedBackgroundColor: "red",
    // },
    // {
    //   selectedFontColor: "white",
    //   label: "AUTO",
    //   value: 2,
    //   selectedBackgroundColor: "orange",
    // },
  ];

  const initialSelectedIndex = options.findIndex(
    ({ value }) => value === "Manual"
  );


  const onClickEachCT=(data)=>{
    props.history.push({
      pathname: `/admin/GlCoolingTower`,
      state: {
        data: data
      }
    });
  }

  const onClickPump=(type,data)=>{
 props.history.push({
      pathname: `/admin/GlPrimaryPump`,
      state: {
        click: 'heading',
        type: type,
        data: data
      }
    });
  }

  const onClickEachPump=(name,data)=>{
    setPumpModal(true)
    setPumpType(name)
    setPumpData(data)
  }

  const handleClick = (data) => {
      console.log("single Click")
      setModalHeading(data)
      setModal(true)  
  }

  const handleDoubleClick = () => {
    console.log("Double Click")
    // setModalHeading(data)
    setModal2(true)  
}
  
  const handleClose = () => {
    setPumpModal(false)
    setModal(false);
    setTriggerModal(false)
  };

  const handleClose2 = () => {
    setModal2(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setModal2(true)  
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleerrorclose = () => {
    setOpenerr(false);
    setErrmsg('');
  };
  const onClickTriggerIcon=(data)=>{
    setTriggerModal(true)
  }

  const onClickOfTrigger = (data) => {
    let req={}
    if(data == 'start'){
      req={"startCPMScenario":"START_CHILLER_SYSTEM"}
    }else if(data=='updates'){
      req={}
    }
    api.floor.startCPMTrigger(req).then((response)=>{
        if(response !== null && response!== undefined && response.data!== null &&  response.data!=undefined){
          // console.log("api res",response.data)

          setCPMRes(JSON.stringify(response.data,null,' '))
      }
    })
    .catch((error)=>{
        console.log("api err",error) 
      setCPMRes(error)
    })
  };

  const DeviceAlarms = (response,devicetype) => {
    let respp = Object.values(response).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
    if (response.length !== 0) {
      let sdevices = []
      let CriticalTotal = 0
      let solutionTotal = 0
      if (alerts.system.length === 0 && alerts.solution.length === 0) {
        let con = 0
        respp.map(element => {
          let obj = {}
          con++
          obj["name"] = element.name
          obj["id"] = element.id
          obj["type"] = element.type
          obj["Eqp_Attributes"] = element["Eqp_Attributes"]
          obj["Eqp_Metrics"] = element["Eqp_Metrics"]
          obj["alerts_cri"] = 0
          obj["alerts_solu"] = 0
          sdevices.push(obj)
          if (respp.length === con) {
            if(devicetype=="Chillers")setEachChillerData(sdevices)
            if(devicetype=="Primary Pumps")setPriPAlerts(sdevices)
            if(devicetype=="Secondary Pumps")setSecPAlerts(sdevices)
            if(devicetype=="Condenser Pumps")setCondenserAlerts(sdevices)
            if(devicetype=="Cooling Towers")setCTAlerts(sdevices)
          }
          return element
        })
      }

      if (alerts.system.length > 0 && alerts.solution.length > 0) {
        respp.map(element => {
          let obj = {}
          obj["name"] = element.name
          obj["id"] = element.id
          obj["type"] = element.type
          obj["Eqp_Attributes"] = element["Eqp_Attributes"]
          obj["Eqp_Metrics"] = element["Eqp_Metrics"]
          let count = 0
          let ci = 0
          alerts.system.map(ele => {
            if (element.id === ele.device_id) {
              count++
              ci++
              CriticalTotal++
              if (alerts.system.length === count) {
                obj["alerts_cri"] = ci
                  if(devicetype=="Chillers")setcriticalAlertsChiller(CriticalTotal)
                  if(devicetype=="Primary Pumps")setcriticalAlertsPP(CriticalTotal)
                  if(devicetype=="Secondary Pumps")setcriticalAlertsSP(CriticalTotal)
                  if(devicetype=="Condenser Pumps")setcriticalAlertsCondenser(CriticalTotal)
                  if(devicetype=="Cooling Towers")setcriticalAlertsCoolT(CriticalTotal)
                let si = 0
                let counts = 0
                alerts.solution.map(ele1 => {
                  if (element.name === ele1.device_name) {
                    counts++
                    si++
                    solutionTotal++
                    if (alerts.solution.length === counts) {
                      obj["alerts_solu"] = si
                      sdevices.push(obj)
                      if(devicetype=="Chillers"){
                        setsoluAlertsChiller(solutionTotal)
                        setEachChillerData(sdevices)
                      }
                      if(devicetype=="Primary Pumps"){
                        setsoluAlertsPP(solutionTotal)
                        setPriPAlerts(sdevices)
                      }
                      if(devicetype=="Secondary Pumps"){
                        setsoluAlertsSP(solutionTotal)
                        setSecPAlerts(sdevices)
                      }
                      if(devicetype=="Condenser Pumps"){
                        setsoluAlertsCondenser(solutionTotal)
                        setCondenserAlerts(sdevices)
                      }
                      if(devicetype=="Cooling Towers"){
                        setsoluAlertsCoolT(solutionTotal)
                        setCTAlerts(sdevices)
                      }
                    }
                  } else {
                    counts++
                    if (alerts.solution.length === counts) {
                      obj["alerts_solu"] = si
                      sdevices.push(obj)
                      if(devicetype=="Chillers"){
                        setsoluAlertsChiller(solutionTotal)
                        setEachChillerData(sdevices)
                      }
                      if(devicetype=="Primary Pumps"){
                        setsoluAlertsPP(solutionTotal)
                        setPriPAlerts(sdevices)
                      }
                      if(devicetype=="Secondary Pumps"){
                        setsoluAlertsSP(solutionTotal)
                        setSecPAlerts(sdevices)
                      }
                      if(devicetype=="Condenser Pumps"){
                        setsoluAlertsCondenser(solutionTotal)
                        setCondenserAlerts(sdevices)
                      }
                      if(devicetype=="Cooling Towers"){
                        setsoluAlertsCoolT(solutionTotal)
                        setCTAlerts(sdevices)
                      }
                    }
                  }
                  return ele1
                })

              }
            } else {
              count++
              if (alerts.system.length === count) {
                obj["alerts_cri"] = ci
                if(devicetype=="Chillers")setcriticalAlertsChiller(CriticalTotal)
                if(devicetype=="Primary Pumps")setcriticalAlertsPP(CriticalTotal)
                if(devicetype=="Secondary Pumps")setcriticalAlertsSP(CriticalTotal)
                if(devicetype=="Condenser Pumps")setcriticalAlertsCondenser(CriticalTotal)
                if(devicetype=="Cooling Towers")setcriticalAlertsCoolT(CriticalTotal)
                let si = 0
                let counts = 0
                alerts.solution.map(ele => {
                  if (element.id === ele.device_id) {
                    counts++
                    si++
                    solutionTotal++
                    if (alerts.solution.length === counts) {
                      obj["alerts_solu"] = si
                      sdevices.push(obj)
                      if(devicetype=="Chillers"){
                        setsoluAlertsChiller(solutionTotal)
                        setEachChillerData(sdevices)
                      }
                      if(devicetype=="Primary Pumps"){
                        setsoluAlertsPP(solutionTotal)
                        setPriPAlerts(sdevices)
                      }
                      if(devicetype=="Secondary Pumps"){
                        setsoluAlertsSP(solutionTotal)
                        setSecPAlerts(sdevices)
                      }
                      if(devicetype=="Condenser Pumps"){
                        setsoluAlertsCondenser(solutionTotal)
                        setCondenserAlerts(sdevices)
                      }
                      if(devicetype=="Cooling Towers"){
                        setsoluAlertsCoolT(solutionTotal)
                        setCTAlerts(sdevices)
                      }
                    }
                  } else {
                    counts++
                    if (alerts.solution.length === counts) {
                      obj["alerts_solu"] = si
                      sdevices.push(obj)
                      if(devicetype=="Chillers"){
                        setsoluAlertsChiller(solutionTotal)
                        setEachChillerData(sdevices)
                      }
                      if(devicetype=="Primary Pumps"){
                        setsoluAlertsPP(solutionTotal)
                        setPriPAlerts(sdevices)
                      }
                      if(devicetype=="Secondary Pumps"){
                        setsoluAlertsSP(solutionTotal)
                        setSecPAlerts(sdevices)
                      }
                      if(devicetype=="Condenser Pumps"){
                        setsoluAlertsCondenser(solutionTotal)
                        setCondenserAlerts(sdevices)
                      }
                      if(devicetype=="Cooling Towers"){
                        setsoluAlertsCoolT(solutionTotal)
                        setCTAlerts(sdevices)
                      }
                    }
                  }
                  return ele
                })
              }
            }
            return ele
          })
          return element
        })
      }

      if (alerts.system.length > 0 && alerts.solution.length === 0) {
        respp.map(element => {
          let obj = {}
          obj["name"] = element.name
          obj["id"] = element.id
          obj["type"] = element.type
          obj["Eqp_Attributes"] = element["Eqp_Attributes"]
          obj["Eqp_Metrics"] = element["Eqp_Metrics"]
          let count = 0
          let ci = 0
          alerts.system.map(ele => {
            if (element.id === ele.device_id) {
              count++
              ci++
              CriticalTotal++
              if (alerts.system.length === count) {
                obj["alerts_cri"] = ci
                obj["alerts_solu"] = 0
                if(devicetype=="Chillers")setcriticalAlertsChiller(CriticalTotal)
                if(devicetype=="Primary Pumps")setcriticalAlertsPP(CriticalTotal)
                if(devicetype=="Secondary Pumps")setcriticalAlertsSP(CriticalTotal)
                if(devicetype=="Condenser Pumps")setcriticalAlertsCondenser(CriticalTotal)
                if(devicetype=="Cooling Towers")setcriticalAlertsCoolT(CriticalTotal)
                  sdevices.push(obj)
                if(devicetype=="Chillers")setEachChillerData(sdevices)
                if(devicetype=="Primary Pumps")setPriPAlerts(sdevices)          
                if(devicetype=="Secondary Pumps")setSecPAlerts(sdevices)          
                if(devicetype=="Condenser Pumps")setCondenserAlerts(sdevices)          
                if(devicetype=="Cooling Towers")setCTAlerts(sdevices)          
                    }
            } else {
              count++
              if (alerts.system.length === count) {
                obj["alerts_cri"] = ci
                obj["alerts_solu"] = 0
                  if(devicetype=="Chillers")setcriticalAlertsChiller(CriticalTotal)
                  if(devicetype=="Primary Pumps")setcriticalAlertsPP(CriticalTotal)
                  if(devicetype=="Secondary Pumps")setcriticalAlertsSP(CriticalTotal)
                  if(devicetype=="Condenser Pumps")setcriticalAlertsCondenser(CriticalTotal)
                  if(devicetype=="Cooling Towers")setcriticalAlertsCoolT(CriticalTotal)
                sdevices.push(obj)
                if(devicetype=="Chillers")setEachChillerData(sdevices)
                if(devicetype=="Primary Pumps")setPriPAlerts(sdevices)       
                if(devicetype=="Secondary Pumps")setSecPAlerts(sdevices)       
                if(devicetype=="Condenser Pumps")setCondenserAlerts(sdevices)       
                if(devicetype=="Cooling Towers")setCTAlerts(sdevices)       
                       }
            }
            return ele
          })
          return element
        })


      }

      if (alerts.system.length === 0 && alerts.solution.length > 0) {
        respp.map(element => {
          let obj = {}
          obj["name"] = element.name
          obj["id"] = element.id
          obj["type"] = element.type
          obj["Eqp_Attributes"] = element["Eqp_Attributes"]
          obj["Eqp_Metrics"] = element["Eqp_Metrics"]
          let count = 0
          let ci = 0
          alerts.solution.map(ele => {
            if (element.id === ele.device_id) {
              count++
              ci++
              solutionTotal++
              if (alerts.solution.length === count) {
                obj["alerts_solu"] = ci
                obj["alerts_cri"] = 0
                sdevices.push(obj)
                if(devicetype=="Chillers")
                  {
                    setsoluAlertsChiller(solutionTotal)
                    setEachChillerData(sdevices)
                  }
                if(devicetype=="Primary Pumps"){
                  setsoluAlertsPP(solutionTotal)
                  setPriPAlerts(sdevices)
                }
                if(devicetype=="Secondary Pumps"){
                  setsoluAlertsSP(solutionTotal)
                  setSecPAlerts(sdevices)
                }
                if(devicetype=="Condenser Pumps"){
                  setsoluAlertsCondenser(solutionTotal)
                  setCondenserAlerts(sdevices)
                }
                if(devicetype=="Cooling Towers"){
                  setsoluAlertsCoolT(solutionTotal)
                  setCTAlerts(sdevices)
                }
              }
            } else {
              count++
              if (alerts.solution.length === count) {
                obj["alerts_solu"] = ci
                obj["alerts_cri"] = 0
                sdevices.push(obj)
                if(devicetype=="Chillers"){
                  setsoluAlertsChiller(solutionTotal)
                  setEachChillerData(sdevices)
                }
                if(devicetype=="Primary Pumps"){
                  setsoluAlertsPP(solutionTotal)
                  setPriPAlerts(sdevices)
                }
                if(devicetype=="Secondary Pumps"){
                  setsoluAlertsSP(solutionTotal)
                  setSecPAlerts(sdevices)
                }  
                if(devicetype=="Condenser Pumps"){
                  setsoluAlertsCondenser(solutionTotal)
                  setCondenserAlerts(sdevices)
                }  
                if(devicetype=="Cooling Towers"){
                  setsoluAlertsCoolT(solutionTotal)
                  setCTAlerts(sdevices)
                }  
                            }
            }
            return ele
          })
          return element
        })
      }

    }
    else {
      if(devicetype=="Chillers")setEachChillerData([])
      if(devicetype=="Primary Pumps")setPriPAlerts([])
      if(devicetype=="Secondary Pumps")setSecPAlerts([])
      if(devicetype=="Condenser Pumps")setCondenserAlerts([])
      if(devicetype=="Cooling Towers")setCTAlerts([])
    }
  }

  useEffect(()=>{

  if(processDataFromJson == true){
  // api.floor.devicemap(campus_id,'PUMPS').then((res) => {
    api.floor.cpmdevicemap().then((res) => {
      // console.log('trueeeeeeeeeee',res)
      // if(res["NONGL_SS_HEADER"]){
      //   let header = getJSONElement(res,["equipmentStatus","NONGL_SS_PRIMARY_PUMP"])
      //   setCPMOnOffStatus(header == true? 1 : 0)
      // }
      if(res.equipmentStatus.NONGL_SS_PRIMARY_PUMP){
        let pumps = getJSONElement(res,["equipmentStatus","NONGL_SS_PRIMARY_PUMP"])
        setPrimaryPump(pumps)
      }
      if(res.equipmentStatus.NONGL_SS_SECONDARY_PUMP){
        let secondarypumps = getJSONElement(res,["equipmentStatus","NONGL_SS_SECONDARY_PUMP"])
        setSecondaryPump(secondarypumps)
      }
      if(res.equipmentStatus.NONGL_SS_COOLING_TOWER){
        let coolingTowers = getJSONElement(res,["equipmentStatus","NONGL_SS_COOLING_TOWER"])
        setCoolingTowers(coolingTowers)
      }
      if(res.equipmentStatus.NONGL_SS_CONDENSER_PUMP){
        let condensers = getJSONElement(res,["equipmentStatus","NONGL_SS_CONDENSER_PUMP"])
        setCondensers(condensers)
      }

  }).catch((error) => {
    setOpenerr(true)
    if(error.response){
      setErrmsg(error.response.data.message)
      }else{
        setErrmsg('')
      }
  })
  }
  else{
    api.floor.cpmGetDevData().then((res)=>{
      if(res["NONGL_SS_CHILLER"]){
        // setEachChillerData(getJSONElement(res,["NONGL_SS_CHILLER"]))
        setEachChillerData(res["NONGL_SS_CHILLER"])
      }
      if(res["NONGL_SS_HEADER"]){
        localStorage.setItem("CPM_AM_Status",Object.values(res["NONGL_SS_HEADER"])[0]["Eqp_Metrics"]["Monitor_Parameter"])
        setCPMOnOffStatus(Object.values(res["NONGL_SS_HEADER"])[0]["Eqp_Metrics"]["Monitor_Parameter"] == true? 1 : 0)
      }
      // if(res["NONGL_SS_PUMPS"]){
      //   setPrimaryPump(res["NONGL_SS_PUMPS"])
      // }
      // if(res["NONGL_SS_SECONDARY_PUMPS"]){
      //   setSecondaryPump(res["NONGL_SS_SECONDARY_PUMPS"])
      // }
      // if(res["NONGL_SS_CONDENSER_PUMPS"]){
      //   setCondensers(res["NONGL_SS_CONDENSER_PUMPS"])
      // }
      // if(res["NONGL_SS_COOLING_TOWER"]){
      //   setCoolingTowers(res["NONGL_SS_COOLING_TOWER"])
      // }
      //calling alerts method
      console.log('calling alerts method')
      DeviceAlarms(res["NONGL_SS_CHILLER"],"Chillers")
      DeviceAlarms(res["NONGL_SS_PUMPS"],"Primary Pumps")
      DeviceAlarms(res["NONGL_SS_SECONDARY_PUMPS"],"Secondary Pumps")
      DeviceAlarms(res["NONGL_SS_CONDENSER_PUMPS"],"Condenser Pumps")
      DeviceAlarms(res["NONGL_SS_COOLING_TOWER"],"Cooling Towers")

      const firstObject = res["NONGL_SS_CHILLER"];

      if(firstObject[Object.keys(res.NONGL_SS_CHILLER)[0]].id != undefined){
      api.floor.chillerGraphDataLast1Hr(firstObject[Object.keys(res.NONGL_SS_CHILLER)[0]].id).then((res)=>{
        if(res.graphData.length){
          if(res.graphData[0]['CWH_ST']){
              setCWH_ST(res.graphData[0]['CWH_ST'])
          }
          if(res.graphData[0]['CWH_RT']){
            // console.log("res.graphData[0]['CWH_RT']",res.graphData[0]['CWH_RT'])
            setCWH_RT(res.graphData[0]['CWH_RT'])
        }
          if(res.graphData[0]['CndW_HST']){
            setCndW_HST(res.graphData[0]['CndW_HST'])
          }
          if(res.graphData[0]['CndW_HRT']){
            setCndW_HRT(res.graphData[0]['CndW_HRT'])
          }
        
        }
      }).catch((error)=>{
        setOpenerr(true)
          if(error.response){
            setErrmsg(error.response.data.message)
            }else{
              setErrmsg('')
            }
      })
    }
    })
    .catch((error)=>{
      if(error.response){
        setErrmsg(error.response.data.message)
      }else{
        setErrmsg('')
      }
      setOpenerr(true)
    })
    api.floor.cpmCurrentState().then((res)=>{
      setCPMCurrentStateData(res)
    })
    .catch((error)=>{
      if(error.response){
        setErrmsg(error.response.data.message)
      }else{
        setErrmsg('')
      }
      // setOpenerr(true)
    })
  }

  const timer = setInterval(() => { 
    api.floor.cpmCurrentState().then((res)=>{
      setCPMCurrentStateData(res)
    })
    .catch((error)=>{
      if(error.response){
        setErrmsg(error.response.data.message)
      }else{
        setErrmsg('')
      }
      // setOpenerr(true)
    }) 
    api.floor.cpmGetDevData().then((res)=>{
      // if(res["NONGL_SS_CHILLER"]){
      //   // setEachChillerData(getJSONElement(res,["NONGL_SS_CHILLER"]))
      //   setEachChillerData(res["NONGL_SS_CHILLER"])
      // }
      DeviceAlarms(res["NONGL_SS_COOLING_TOWER"],"Cooling Towers")
      DeviceAlarms(res["NONGL_SS_PUMPS"],"Primary Pumps")
      DeviceAlarms(res["NONGL_SS_SECONDARY_PUMPS"],"Secondary Pumps")
      DeviceAlarms(res["NONGL_SS_CONDENSER_PUMPS"],"Condenser Pumps")
      if(res["NONGL_SS_HEADER"]){
        localStorage.setItem("CPM_AM_Status",Object.values(res["NONGL_SS_HEADER"])[0]["Eqp_Metrics"]["Monitor_Parameter"])
        setCPMOnOffStatus(Object.values(res["NONGL_SS_HEADER"])[0]["Eqp_Metrics"]["Monitor_Parameter"] == true? 1 : 0)
      }
      if(res["NONGL_SS_PUMPS"]){
        setPrimaryPump(res["NONGL_SS_PUMPS"])
      }
      if(res["NONGL_SS_SECONDARY_PUMPS"]){
        setSecondaryPump(res["NONGL_SS_SECONDARY_PUMPS"])
      }
      if(res["NONGL_SS_CONDENSER_PUMPS"]){
        setCondensers(res["NONGL_SS_CONDENSER_PUMPS"])
      }
      if(res["NONGL_SS_COOLING_TOWER"]){
        setCoolingTowers(res["NONGL_SS_COOLING_TOWER"])
      }
    })
    .catch((error)=>{
      if(error.response){
        setErrmsg(error.response.data.message)
      }else{
        setErrmsg('')
      }
      setOpenerr(true)
    })
   }, 3000);
  return () => clearInterval(timer);
  },[alerts.system.length,alerts.solution.length])

  const numEmptyCards = 9 - Object.entries(coolingTowers).length; // Adjust the total number as needed
  // Create an array of empty cards
  const emptyCardsForCT = Array.from({ length: numEmptyCards }, (_, index) => (    
  <button className={classes.smallbuttons} style={{backgroundColor:"rgb(239 229 229 / 87%)", color: 'white'}}></button>
  ));

  const numEmptyCardsForPriPumps = 9 - Object.entries(primaryPump).length; // Adjust the total number as needed
  const emptyCardsForPriPumps = Array.from({ length: numEmptyCardsForPriPumps }, (_, index) => (
    <button className={classes.smallbuttons} style={{ backgroundColor:"rgb(239 229 229 / 87%)", color: 'white'}}></button>
  ));


  const numEmptyCardsForSecPumps = 9 - Object.entries(secondaryPump).length; // Adjust the total number as needed

  const emptyCardsForSecPumps = Array.from({ length: numEmptyCardsForSecPumps }, (_, index) => (
    <button className={classes.smallbuttons} style={{ backgroundColor:"rgb(239 229 229 / 87%)", color: 'white'}}></button>
  ));

  const numEmptyCardsForCPumps = 9 - Object.entries(condensers).length; // Adjust the total number as needed

  const emptyCardsForCPumps = Array.from({ length: numEmptyCardsForCPumps }, (_, index) => (
    <button className={classes.smallbuttons} style={{ backgroundColor:"rgb(239 229 229 / 87%)", color: 'white'}}></button>
  ));

  const onChangeCPMStatus = (newValue) => {
    setCPMOnOffStatus(newValue)
    let autoManual =(newValue == 0 ? false: true)
    let req={
      autoManual
    }
    api.floor.cpmHeaderOnOffControl(req).then((response)=>{
      console.log('api resppp',response,newValue)
      if(response){
        localStorage.setItem("CPM_AM_Status",newValue == 0 ? false: true)
        toast({
          type: "success",
          icon: "check circle",
          title: "Success",
          description: response,
          time: 2000,
        });
      }
    }).catch((error)=>{
      toast({
        type: "error",
        icon: "exclamation triangle",
        title: "Error",
        // description: response,
        time: 2000,
      });
    })
  }

  return (
    <div className={classes.root}>
            <Snackbar open={openerr} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
              <Alert style={{ cursor: "pointer" }} severity="error" variant="filled" onClose={handleerrorclose}>
                {errmsg}
              </Alert>
            </Snackbar>
            <Grid container item xs={12} spacing={1}>
                {/* Left-top part */}
                <Grid item xs={12} spacing={1} sm={12} md={9} lg={9} xl={9} xxl={9}>
                    <Grid container item xs={12} spacing={1}>
                      <LandingPage processDataFromJson={processDataFromJson} device={chillers} criticalAlerts={criticalAlertsChiller+criticalAlertsCoolT+criticalAlertsPP+criticalAlertsSP+criticalAlertsCondenser} soluAlerts={soluAlertsChiller+soluAlertsCoolT+soluAlertsPP+soluAlertsSP+soluAlertsCondenser} type="CPM" />
                    </Grid>     
                    <Grid container item xs={12} spacing={1} style={{marginTop:"1vh"}}>
                            <Grid item spacing={1} xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} direction='column'>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Card className={classes.childpaper} style={{height:window.innerHeight=='1280'?"20vh":"15vh"}}>
                                          <div className={classes.CardHeadFont} style={{marginTop: window.innerHeight=='1280'?"1vh":'-1vh',fontWeight:'bold',color:'black'}}>Cooling Tower</div>
                                            <Grid container direction="row">
                                                 {ctAlerts.map((element, index) =>
                                                 <StyledTooltip title={element.ss_tag} className={classes.tooltip} arrow>
                                                  <button className={classes.smallbuttons} style={{overflowX:'hidden',overflowY:element.length>'3'?'auto':'hidden', 
                                                  // backgroundColor: "grey",
                                                  backgroundColor:element[3]==true?"#FF0000":element.alerts_cri>0?"#CE1E1E":element.alerts_solu>0?"#f2aa1a":element["Eqp_Attributes"]["CT_Out_Vlv_On_Off_Fbk"]!= undefined?((element["Eqp_Attributes"]["CT_Out_Vlv_On_Off_Fbk"]["presentValue"] == ("inactive"||0))?"grey":(element["Eqp_Attributes"]["CT_Out_Vlv_On_Off_Fbk"]["presentValue"] == ("active"||1))?"#21ba45":"grey"):"grey",
                                                  color:'white',boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)"}} 
                                                  onClick={()=>onClickEachCT(element)}>{processDataFromJson == true ?element[3]:element.name}</button>
                                                  </StyledTooltip>
                                                  )}
                                                  {emptyCardsForCT}
                                            </Grid>
                                        </Card>
                                    </Grid> 
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Box className={classes.childpaper} style={{height:window.innerHeight=='1280'?"20vh":'15.5vh'}}>
                                          <div className={classes.CardHeadFont} style={{marginTop: window.innerHeight=='1280'?"1vh":'-1vh',fontWeight:'bold',color:'black',cursor:'pointer'}} 
                                          // onClick={()=>onClickPump("PrimaryPump",primaryPump)}
                                          >Primary Pump</div>
                                          <Grid container direction="row">
                                            {pripAlerts.map((element, index) =>
                                                  <StyledTooltip title={element.ss_tag} className={classes.tooltip} arrow>
                                                  <button className={classes.smallbuttons} style={{ backgroundColor:element[6]==true?"#FF0000":element[3]=='0'?"grey":element.alerts_cri>0?"#CE1E1E":element.alerts_solu>0?"#f2aa1a":element["Eqp_Attributes"]["Pri_Pmp_On/Off_SS"]!= undefined?((element["Eqp_Attributes"]["Pri_Pmp_On/Off_SS"]["presentValue"] == ("inactive"||0))?"grey":(element["Eqp_Attributes"]["Pri_Pmp_On/Off_SS"]["presentValue"] == ("active"||1))?"#21ba45":"grey"):"grey",color:'white' }} onClick={()=>onClickEachPump("PrimaryPump",element)}>{processDataFromJson == true ?element[3]:element.name}</button>
                                                  </StyledTooltip>
                                                  )}
                                                  {emptyCardsForPriPumps}
                                          </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Box className={classes.childpaper} style={{height:window.innerHeight=='1280'?"20vh":'15.5vh'}}>
                                          <div className={classes.CardHeadFont} style={{marginTop: window.innerHeight=='1280'?"1vh":'-1vh',fontWeight:'bold',color:'black',cursor:'pointer'}} 
                                          // onClick={()=>onClickPump("SecondaryPump",secondaryPump)}
                                          >Secondary Pump</div>
                                          <Grid container spacing={1}>
                                              {secpAlerts.map((element, index) =>
                                                      <StyledTooltip title={element.ss_tag} className={classes.tooltip} arrow>
                                                      <button className={classes.smallbuttons} style={{ backgroundColor:element[6]==true?"#FF0000":element[3]=='0'?"grey":element.alerts_cri>0?"#CE1E1E":element.alerts_solu>0?"#f2aa1a":element["Eqp_Attributes"]["Sec_Pmp_SS"]!=undefined?((element["Eqp_Attributes"]["Sec_Pmp_SS"]["presentValue"] == ("inactive"||0))?"grey":(element["Eqp_Attributes"]["Sec_Pmp_SS"]["presentValue"] == ("active"||1))?"#21ba45":"grey"):"grey",color:'white' }} onClick={()=>onClickEachPump("SecondaryPump",element)}>{processDataFromJson == true ?element[3]:element.name}</button>
                                                      </StyledTooltip>
                                                 )}
                                                 {emptyCardsForSecPumps}
                                          </Grid>
                                        </Box>                              
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Box className={classes.childpaper} style={{height:window.innerHeight=='1280'?"20vh":'17.5vh'}}>
                                              <div className={classes.CardHeadFont} style={{marginTop: window.innerHeight=='1280'?"1vh":'-1vh',fontWeight:'bold',color:'black'}}>Condenser Water Pump</div>
                                              <Grid container direction="row">
                                              {condenserAlerts.map((element, index) =>
                                                      <StyledTooltip title={element.ss_tag} className={classes.tooltip} arrow>
                                                      <button className={classes.smallbuttons} 
                                                      style={{ backgroundColor: element[6]==true?"#FF0000":element[3]=='0'?"grey":element.alerts_cri>0?"#CE1E1E":element.alerts_solu>0?"#f2aa1a":
                                                      element["Eqp_Attributes"]["Cnd_Pmp_SS"]!=undefined?((element["Eqp_Attributes"]["Cnd_Pmp_SS"]["presentValue"] == ("inactive"||0))?"grey":(element["Eqp_Attributes"]["Cnd_Pmp_SS"]["presentValue"] == ("active"||1))?"#21ba45":"grey"):"grey"
                                                      // give code for null and alarm
                                                      ,color:'white',boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)" }} onClick={()=>onClickEachPump("CondenserPump",element)}>
                                                        {processDataFromJson == true ?element[3]:element.name}</button>
                                                      </StyledTooltip>  
                                                 )}
                                                 {emptyCardsForCPumps}
                                               </Grid>
                                        </Box>                            
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={10} lg={10} xl={10} xxl={10}>
                                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} direction='column'>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Card className={classes.imagecard} style={{backgroundColor:'#E5E5E5'}}>
                                              <Map
                                                    ref={mapRef}
                                                    doubleClickZoom={false}
                                                    zoomControl={false}
                                                    dragging={false}
                                                    scrollWheelZoom={false}
                                                    crs={Leaflet.CRS.Simple}
                                                    center={[0, 0]}
                                                    attributionControl={false}
                                                    // bounds={[[0, 0], [600, 730]]}
                                                    bounds={[[0, 0], [470, 600]]}
                                                    // bounds={[[0, 0], [420, 600]]}
                                                    className={"floor-map"}
                                                    // style={{marginLeft:'-1vh',marginTop:'-1vh',width:"89vh",height:'67.5vh',}}
                                                    // style={{backgroundColor:"white"}}
                                                    onClick={(e) => { console.log({ x: e.latlng.lat, y: e.latlng.lng }) }}
                                                  >
                                                    <ImageOverlay
                                                      interactive
                                                      // url={'https://localhost/' + image + '.png'}
                                                      url={image}
                                                      // bounds={[[50, 15], [600, 730]]}
                                                      // bounds={[[100, -8], [525, 640]]}
                                                      // bounds={[[0, 0], [470, 590]]}
                                                      bounds={[[0, 10], [470, 590]]}
                                                    />                                            
                                                    <ZoomControl position="bottomright" />
                                              </Map>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Card className={classes.imagecard} style={{backgroundColor:'#E5E5E5',height:'4vh'}}>
                                        <marquee width="100%" direction="left" height="100px">
                                            Current Scenario: {cpmCurrentStatedata.currentScenario? cpmCurrentStatedata.currentScenario: " - "} ,Current State: {cpmCurrentStatedata.currentState? cpmCurrentStatedata.currentState:" - "}
                                        </marquee>
                                        {/* <div style={{display:'flex',justifyContent:'center',alignItems:'center',whiteSpace:"nowrap"}}>Current Scenario: {cpmCurrentStatedata.currentScenario? cpmCurrentStatedata.currentScenario: " - "} ,Current State: {cpmCurrentStatedata.currentState? cpmCurrentStatedata.currentState:" - "}</div> */}
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
                {/* Right part */}
                <Grid item xs={12} spacing={1} sm={12} md={3} lg={3} xl={3} xxl={3} style={{marginTop:"1vh"}}>
                              <Grid container item xs={12} spacing={1} direction='column'>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                      <Grid container xs={12} spacing ={2} direction="row" style={{marginTop:'-1vh',display:'flex',justifyContent:'center'}}>
                                                      <Grid item xs={2} style={{display:'flex',justifyContent:'center'}}>
                                                        <Typography variant="string" style={{color:'black',fontWeight:'bold',fontSize:'2vh'}}>
                                                             CPM
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item xs={7}>
                                                      <div className="your-required-wrapper"
                                                          style={{ width: "20vh", marginLeft: "0vh",height:"3.5vh" }}>
                                                                    <SwitchSelector
                                                                      onChange={onChangeCPMStatus}
                                                                      options={options}
                                                                      // initialSelectedIndex={initialSelectedIndex}
                                                                      forcedSelectedIndex={cpmOnOffStatus}
                                                                      backgroundColor={"rgba(0, 0, 0, 0.04)"}
                                                                      fontColor={"#000"}
                                                                      selectedFontColor={"#000"}
                                                                      // border="5"
                                                                      optionBorderRadius={5}
                                                                      wrapperBorderRadius={8}
                                                                      fontSize={11}
                                                                    />
                                                                  </div>
                                                      </Grid>
                                                      {/* <Grid item xs={2} style={{display:'flex',justifyContent:'center'}}><CreateIcon style={{cursor:'pointer'}}  onClick={() => onClickTriggerIcon('Icon')}/></Grid> */}
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Box className={classes.graphpaper}>
                                          <Grid container item xs={12} spacing={1}>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                  <Card className={`${classes.semicircularbar} ${classes.CardHeadFont}`} style={{fontWeight:'bold',color:'black',boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)", backgroundColor:"#Fefefa"}}>Plant Power[kW]
                                                  {/* <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage='50' showPercentValue /> */}
                                                  <div className={classes.semicircularbarcomp}>
                                                    <SemiCircleProgressBar
                                                        stroke="#0123b4"
                                                        strokeWidth={20}
                                                        diameter={100}
                                                        orientation="up"
                                                        percentage={50} // Set the value here
                                                    // showPercentValue
                                                    />
                                                <div style={{ marginTop: '-2vh', fontSize: '10px' }}>50</div>
                                                </div>
                                                  </Card>
                                              </Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                  <Card className={`${classes.semicircularbar} ${classes.CardHeadFont}`} onClick={() => handleClick('Plant Load')} style={{fontWeight:'bold',color:'black',cursor:'pointer'}}>Plant Load
                                                  {/* <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage='50' showPercentValue /> */}
                                                  <div className={classes.semicircularbarcomp}>
                                                    <SemiCircleProgressBar
                                                        stroke="#0123b4"
                                                        strokeWidth={20}
                                                        diameter={100}
                                                        orientation="up"
                                                        percentage={50} // Set the value here
                                                    // showPercentValue
                                                    />
                                                <div style={{ marginTop: '-2vh', fontSize: '10px' }}>50</div>
                                                </div>
                                                  </Card>
                                              </Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                  <Card className={`${classes.semicircularbar} ${classes.CardHeadFont}`} 
                                                  onClick={() => handleClick('Plant kW/TR')}
                                                  style={{fontWeight:'bold',color:'black',cursor:'pointer'}}>Plant kW/TR
                                                   <div className={classes.semicircularbarcomp}>
                                                    <SemiCircleProgressBar
                                                        stroke="#0123b4"
                                                        strokeWidth={20}
                                                        diameter={100}
                                                        orientation="up"
                                                        percentage={50} // Set the value here
                                                    // showPercentValue
                                                    />
                                                <div style={{ marginTop: '-2vh', fontSize: '10px' }}>50</div>
                                                </div>
                                                  {/* <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage='50' showPercentValue /> */}
                                                  </Card>
                                              </Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                  <Card className={classes.semicircularbar}
                                                    style={{fontWeight:'bold',color:'black',cursor:'pointer'}}>
                                                      <div  className={classes.CardHeadFont}
                                                  //     onMouseEnter={handleMouseEnter}
                                                  // onMouseLeave={handleMouseLeave}
                                                  >Run Hours</div><br />
                                                  {/* <u style={{color:'blue'}}><a onClick={() => handleDoubleClick()} style={{cursor:"pointer" }}>Reports</a></u> */}
                                                  {/* <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage='50' showPercentValue /> */}
                                                  <div  onClick={() => handleClick('Run Hours')} style={{fontWeight:'bold',color:'#0123B4',fontSize:'3.5vh',marginTop:'-1vh'}}>400</div>
                                                  </Card>
                                              </Grid>
                                          </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Box className={classes.graphpaper}>
                                          <div style={{fontWeight:'bold',color:'black'}} className={classes.CardHeadFont}>Chilled Water Temperature</div>
                                          <TimeS
                                          name='Chilled Water Temperature'
                                          data={CWH_ST} data2={CWH_RT} 
                                      style={{ width: "100%", height: "100%" }}
                                    ></TimeS>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Box className={classes.graphpaper}>
                                          <div style={{fontWeight:'bold',color:'black'}}  className={classes.CardHeadFont}>Condenser Water Temperature</div>
                                          <TimeS
                                        name='Condenser Water Temperature'
                                        data={CndW_HST} data2={CndW_HRT}
                                      style={{ width: "100%", height: "100%" }}
                                    ></TimeS>
                                        </Box>
                                    </Grid>
                                </Grid>
                </Grid>
            </Grid>
            {/* <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title"  open={modal} classes={{ paper: classes.customDialog }}>
                      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                     {modalHeading}
                      </DialogTitle>
                      <DialogContent dividers>
                      <TimeS
                                  // name='kW/TR'
                                  data={dataSeries}
                                  style={{ width: "100%", height: "50%" }}
                                  // style={{ width: "100%", height: "100%" }}
                                ></TimeS>
                      </DialogContent>
            </Dialog>   */}
             <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title"  open={pumpModal} classes={{ paper: classes.customDialogPump }}>
                      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                           {pumpData[3]}
                      </DialogTitle>
                      <DialogContent dividers>
                          <PumpPage type={pumpType} data={pumpData} click='eachPump' />
                      </DialogContent>
            </Dialog>  
            {/* <Dialog onClose={handleClose2} aria-labelledby="customized-dialog-title"  open={modal2} classes={{ paper: classes.customDialog }}>
                      <DialogTitle id="customized-dialog-title" onClose={handleClose2}>
                     Reports
                      </DialogTitle>
                      <DialogContent dividers>
                      <ChillerReports data={'Jahnavi'}/>
                     </DialogContent>
            </Dialog>   */}
             <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title"  open={triggerModal} classes={{ paper: classes.customDialogPump }}>
                      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                           CPM
                      </DialogTitle>
                      <DialogContent dividers>
                          <Grid container item xs={12} spacing={1}>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                          {/* <Box className={classes.childpaper} style={{height:'17.5vh'}}> */}
                                                <Grid container item xs={12} direction="column">
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                      <Card className={classes.graphpaper} style={{height:'20.5vh',width:'72vh',display:'flex',justifyContent:'center',alignItems:'center',fontWeight:'bold'}}>{cpmres}</Card>
                                                    </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} direction="row" style={{marginTop:'3vh',marginLeft:'2vh'}}>
                                                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                                        <Paper className={classes.faults_paper} onClick={()=>onClickOfTrigger('start')} style={{cursor:'pointer',height:'5vh',color:'white',width:'20vh',backgroundColor:'#0123B4',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                          Start
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                                    <Paper className={classes.faults_paper} onClick={()=>onClickOfTrigger('updates')} style={{cursor:'pointer',color:'white',height:'5vh',width:'20vh',backgroundColor:'#0123B4',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                          Check updates
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Grid>
                                                </Grid>
                                          {/* </Box>                             */}
                                      </Grid>
                          </Grid>
                      </DialogContent>
            </Dialog> 
            <SemanticToastContainer position="top-center" /> 
    </div>
  )
}
