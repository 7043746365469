import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Box,Card } from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from '@material-ui/core/Typography';
import { Map, ImageOverlay, Marker, Tooltip, ZoomControl } from 'react-leaflet';
import ChillerImg from './../../assets/img/ChillerImg.png'
import TimeSeriesUpsStatic from "./../TimeSeriesUpsStatic";
import SwitchSelector from "react-switch-selector";
import { CalendarToday } from "@material-ui/icons";
import { ButtonBase } from "@material-ui/core";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import ProgressBar from "@ramonak/react-progress-bar";
import api from './../../api'
import TimeS from './../TimeS'
import TimeSeriesChart from './../TimeSeriesVav';
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

const Leaflet = require('leaflet');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  semicircularbarcomp:{
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
        marginTop:'-0.5vh',
        marginLeft:'-1.5vh'
      },
      '@media (min-width:600px) and (max-width:959.95px)': {//sm
        marginTop:'1vh',
        marginLeft:'-0.1vh'
      },
      '@media (min-width:960px) and (max-width:1279.95px)': {//md
        marginTop:'-1vh',
        marginLeft:'-1.1vh'
      },
      '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
        marginTop:'0vh',
        marginLeft:'-0.1vh'
      },
      '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
        marginTop:'1vh',
        marginLeft:'0.5vh'
      },
  },
  paper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // backgroundColor: 'white',
    // borderRadius: '14px',
    borderRadius:"9px",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    height: '50vh',
    marginTop:"1vh",
    opacity:'1'
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor:"#0123b4",borderRadius:"8px"
    },
    "& .MuiSelect-root ": {
      marginTop:"-2vh"
    }
  },
  switchselector:{
    height:'3.5vh',
    [theme.breakpoints.down('sm')]: {
      width:'8.5vh'
    },
    [theme.breakpoints.up('md')]: {
      width:'13vh'
    },
    [theme.breakpoints.up('lg')]: {
      width:'10.5vh'
    },
    [theme.breakpoints.up('xl')]: {
      width:'10.5vh'
    },
  },
  semicircularbar:{
    height:"12vh",
    borderRadius:"6px",
    background:"#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // borderRadius: '14px',
    opacity:"1",
  },
  graphpaper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius:"6px",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    height: "27vh",
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // borderRadius: '14px',
  },
  // controlcard: {
  //   background:'#FFFFFF 0% 0% no-repeat padding-box',
  //   height: "30.5vh",
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  //   boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
  //   backgroundColor:"#fcfafa",
  //   // boxShadow: '0px 4px 20px #0123B41A',
  //   borderRadius: '6px',
  // },
  graphpaper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius:"6px",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    height: "28vh",
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // borderRadius: '14px',
  },
  text: {
    fontSize: "14px",
    color: " #292929",
    fontFamily: "Arial"
  },
  control1: {
    width: "6vh",
    marginTop: "0vh",
    marginLeft: "-11vh",
    fontFamily: "Arial"
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  CardHeadFont:{
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      fontSize: "1.5vh"
      },
      '@media (min-width:600px) and (max-width:959.95px)': {//sm
        fontSize: "1.9vh",
        borderRadius:'10px',
      },
      '@media (min-width:960px) and (max-width:1279.95px)': {//md
        fontSize: "1.7vh",
      },
      '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
        fontSize: "1.8vh",
      },
      '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
        fontSize: "1.7vh",
      },
  },
}));

export default function GlChillerPage(props) {
  // console.log("props in glchillerpage",props.location.state)
  const classes = useStyles();
  const mapRef = React.createRef()
  const [allchillerData, setAllChillerData] = React.useState([])
  const [chillerData, setChillerData] = React.useState({})
  const [chillerOnOff, setChillerOnOff] = React.useState(0)
  const [CH_AM_SS, setCH_AM_SS] = React.useState("")
  const [CWH_ST_SP, setCWH_ST_SP] = React.useState("")
  const [CH_Out_Vlv_On_Off, setCH_Out_Vlv_On_Off] = React.useState("")
  const [RunHours, setRunHours] = React.useState("")
  const [CWH_ST, setCWH_ST] = useState([]);
  const [CWH_RT, setCWH_RT] = useState([]);
  const [CndW_HST, setCndW_HST] = useState([]);
  const [CndW_HRT, setCndW_HRT] = useState([]);
  const [VFD_Ph_Cur, setVFD_Ph_Cur] = useState([]);
  const [selectedChId, setSelectedChId] = useState(props.location.state.data);
  const [selectedChName, setSelectedChName] = useState(props.location.state.name);
  const [openerr,setOpenerr] = React.useState(false);
  const [errmsg,setErrmsg] = React.useState('');
  const [chillerAlarm,setChillerAlarm] = React.useState([]);
  const [selectedChAlarm, setSelectedChAlarm] = useState('DP high and SP normal');
  const [disable, setDisable] = useState(false);
  const [chWIn, setChWIn] = useState("");
  const [chWOut, setChWOut] = useState("");
  const CPM_Status = localStorage.getItem("CPM_AM_Status");
  const iconDevice1 = new Leaflet.Icon({
    iconUrl: require("../../assets/img/sensor-icon.png"),
    iconSize: new Leaflet.Point(0, 0),
    className: "leaflet-div-icon-2",
  });
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const options = [
    {
      selectedFontColor: "white",
      label: "OFF",
      value: 0,
      selectedBackgroundColor: "red",
    },
    {
      selectedFontColor: "white",
      label: "ON",
      value: 1,
      selectedBackgroundColor: "green",
    },
    //  {
    //   selectedFontColor: "white",
    //   label: "AUTO",
    //   value: 2,
    //   selectedBackgroundColor: "#0123B4",
    // }
  ];

  const handleLocationClick = (name) => {
    props.history.push(`/admin/Glschedule`);
  };

  const handleChillerChange = (name, id) => {
    console.log("idddd HCC",id,name)
    setSelectedChId(id)
    setSelectedChName(name)
    Object.values(allchillerData).map((res)=>{
      if( id === res.id )
      {        
        console.log("-->",id)
        setChillerData(res)
        if(res.Eqp_Attributes.CHW_In_Temp){
          setChWIn(res.Eqp_Attributes.CHW_In_Temp.presentValue)
        }
        if(res.Eqp_Attributes.CHW_Out_Temp){
          setChWOut(res.Eqp_Attributes.CHW_Out_Temp.presentValue)
        }
        if(res.Eqp_Attributes.CH_On_Off){
          setChillerOnOff(res.Eqp_Attributes.CH_On_Off.presentValue == "active"? 1:0)
        }
        if(res.Eqp_Attributes.CH_AM_SS){
          setCH_AM_SS(res.Eqp_Attributes.CH_AM_SS.presentValue)
        }
        if(res.Eqp_Attributes.CWH_ST_SP){
          setCWH_ST_SP(res.Eqp_Attributes.CWH_ST_SP.presentValue)
        }
        if(res.Eqp_Attributes.CH_Out_Vlv_On_Off){
          setCH_Out_Vlv_On_Off(res.Eqp_Attributes.CH_Out_Vlv_On_Off.presentValue)
        }
        if(res.Eqp_Metrics.rh_cumulative){
          setRunHours(res.Eqp_Metrics.rh_cumulative)
        }
        if(id){
        api.floor.chillerGraphDataLast1Hr(id).then((response)=>{
          if(response.graphData.length){
            if(response.graphData[0]['CWH_ST']){
                setCWH_ST(response.graphData[0]['CWH_ST'])
            }
             if(response.graphData[0]['CWH_RT']){
              setCWH_RT(response.graphData[0]['CWH_RT'])
           }
             if(response.graphData[0]['CndW_HST']){
              setCndW_HST(response.graphData[0]['CndW_HST'])
            }
             if(response.graphData[0]['CndW_HRT']){
              setCndW_HRT(response.graphData[0]['CndW_HRT'])
            }
            if(response.graphData[0]['VFD_Ph_Cur']){
              setVFD_Ph_Cur(response.graphData[0]['VFD_Ph_Cur'])
            }
          }
        }).catch((error)=>{
          setOpenerr(true)
          if(error.response.data.message){
            setErrmsg(error.response.data.message)
            }else{
              setErrmsg('')
            }
        })
      }
      }
    })
   
  }

  const handleerrorclose = () => {
    setOpenerr(false);
    setErrmsg('');
  };

  const handleChillerAlarmChange = (message, id) => {
    let data ={'id' : id,'message': message}
    setSelectedChAlarm(data)
  }

  const handleSubmitAlarmClick = (data) => {
    // let ss_id = selectedChId, message = data.message
    const req = {
      ss_id : selectedChId,
      message: data.message
    }
    if(selectedChId && data.message){
        setDisable(true)
        setTimeout(() => {     setDisable(false)    }, 5000);
      
        api.floor.insertSelectedChillerAlarm(req).then((res)=>{
          // console.log("api resp",res)
          // setOpenerr(true)
          toast({
            type: 'success',
            icon: 'check circle',
            title: 'Success',
            description: 'Fault injected',
            time: 3000
        });
        })
        .catch((error)=>{
          setOpenerr(true)
          if(error.response.data.message){
            setErrmsg(error.response.data.message)
            }else{
              setErrmsg('')
            }
        })
      }
      else{
        if(selectedChId != undefined){
          setOpenerr(true)
          setErrmsg('Invalid')
        }else if(data.message){
          setOpenerr(true)
          setErrmsg('Please select the fault')
        }
      }
    }

  useEffect(()=>{
    api.floor.cpmGetDevData().then((res)=>{
      if(res.NONGL_SS_CHILLER){
        let data=Object.values(res.NONGL_SS_CHILLER).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
        setAllChillerData(data) 
      }
      Object.values(res.NONGL_SS_CHILLER).map((res)=>{
        if(props.location.state.data === res.id )
        {
          setChillerData(res)
          // setSelectedChId(res.id)
          // setSelectedChName(res.name)
          if(res.Eqp_Attributes.CHW_In_Temp){
            setChWIn(res.Eqp_Attributes.CHW_In_Temp.presentValue)
          }
          if(res.Eqp_Attributes.CHW_Out_Temp){
            setChWOut(res.Eqp_Attributes.CHW_Out_Temp.presentValue)
          }
          if(res.Eqp_Attributes.CH_On_Off){
            setChillerOnOff(res.Eqp_Attributes.CH_On_Off.presentValue == "active"? 1:0)
          }
          if(res.Eqp_Attributes.CH_AM_SS){
            setCH_AM_SS(res.Eqp_Attributes.CH_AM_SS.presentValue)
          }
          if(res.Eqp_Attributes.CWH_ST_SP){
            setCWH_ST_SP(res.Eqp_Attributes.CWH_ST_SP.presentValue)
          }
          if(res.Eqp_Attributes.CH_Out_Vlv_On_Off){
            setCH_Out_Vlv_On_Off(res.Eqp_Attributes.CH_Out_Vlv_On_Off.presentValue)
          }
          if(res.Eqp_Metrics.rh_cumulative){
            setRunHours(res.Eqp_Metrics.rh_cumulative)
          }
          if(res.id){
          api.floor.chillerGraphDataLast1Hr(res.id).then((response)=>{
            if(response.graphData.length){
              if(response.graphData[0]['CWH_ST']){
                  setCWH_ST(response.graphData[0]['CWH_ST'])
              }
               if(response.graphData[0]['CWH_RT']){
                setCWH_RT(response.graphData[0]['CWH_RT'])
             }
               if(response.graphData[0]['CndW_HST']){
                setCndW_HST(response.graphData[0]['CndW_HST'])
              }
               if(response.graphData[0]['CndW_HRT']){
                setCndW_HRT(response.graphData[0]['CndW_HRT'])
              }
              if(response.graphData[0]['VFD_Ph_Cur']){
                setVFD_Ph_Cur(response.graphData[0]['VFD_Ph_Cur'])
              }
            }
          }).catch((error)=>{
            setOpenerr(true)
            if(error.response){
              setErrmsg(error.response.data.message)
              }else{
                setErrmsg('')
              }     
             })
            }
        }
      })
    }).catch((error)=>{
      setOpenerr(true)
      if(error.response){
        setErrmsg(error.response.data.message)
        }else{
          setErrmsg('')
        }
    })
    // api.floor.devicemap(campus_id,'CHILLER').then((res)=>{
    //   console.log("useeffect called",res)
    //   setAllChillerData(res)
    //   res.map((res)=>{
    //     if(props.location.state.data === res.ssid )
    //     {
    //       setChillerData(res)
    //       console.log("res.ssid",res.CH_SS)
    //       api.floor.chillerGraphDataLast1Hr(res.ssid).then((response)=>{
    //         if(response.graphData.length){
    //           if(response.graphData[0]['CWH_ST']){
    //               setCWH_ST(response.graphData[0]['CWH_ST'])
    //           }
    //            if(response.graphData[0]['CWH_RT']){
    //             setCWH_RT(response.graphData[0]['CWH_RT'])
    //          }
    //            if(response.graphData[0]['CndW_HST']){
    //             setCndW_HST(response.graphData[0]['CndW_HST'])
    //           }
    //            if(response.graphData[0]['CndW_HRT']){
    //             setCndW_HRT(response.graphData[0]['CndW_HRT'])
    //           }
    //           if(response.graphData[0]['VFD_Ph_Cur']){
    //             setVFD_Ph_Cur(response.graphData[0]['VFD_Ph_Cur'])
    //           }
    //         }
    //       }).catch((error)=>{
    //         setOpenerr(true)
    //         if(error.response.data.message){
    //           setErrmsg(error.response.data.message)
    //           }else{
    //             setErrmsg('')
    //           }     
    //          })
    //     }
    //   })
    // }).catch((error)=>{
    //   setOpenerr(true)
    //   if(error.response.data.message){
    //     setErrmsg(error.response.data.message)
    //     }else{
    //       setErrmsg('')
    //     }
    // })

    let chillAlarm = [{id:'1',alarm:'DP high and SP normal',Todisplay:"Fault-1"},{id:'2',alarm:'DP high and SP high',Todisplay:"Fault-2"},{id:'3',alarm:'DP normal, SP low and DT normal',Todisplay:"Fault-3"},{id:'4',alarm:'DP low and SP low',Todisplay:"Fault-4"},{id:'5',alarm:'DP normal, SP high and DT low',Todisplay:"Fault-5"},{id:'6',alarm:'DP normal, SP low and DT high',Todisplay:"Fault-6"},{id:'7',alarm:'DP normal, SP normal and DT high',Todisplay:"Fault-7"}];
    setChillerAlarm(chillAlarm)

    const timer = setInterval(() => {
      console.log('selectedChId in interval------------>',selectedChId)
      if(selectedChId){
        // api.floor.devicemap(campus_id,'CHILLER').then((res)=>{
          api.floor.cpmGetDevData().then((resp)=>{
            Object.values(resp.NONGL_SS_CHILLER).map((res)=>{
            if(selectedChId === res.id)
          {
            setChillerData(res)
            if(res.Eqp_Attributes.CHW_In_Temp){
              setChWIn(res.Eqp_Attributes.CHW_In_Temp.presentValue)
            }
            if(res.Eqp_Attributes.CHW_Out_Temp){
              setChWOut(res.Eqp_Attributes.CHW_Out_Temp.presentValue)
            }
            if(res.Eqp_Attributes.CH_On_Off){
              setChillerOnOff(res.Eqp_Attributes.CH_On_Off.presentValue == "active"? 1:0)
            }
            if(res.Eqp_Attributes.CH_AM_SS){
              setCH_AM_SS(res.Eqp_Attributes.CH_AM_SS.presentValue)
            }
            if(res.Eqp_Attributes.CWH_ST_SP){
              setCWH_ST_SP(res.Eqp_Attributes.CWH_ST_SP.presentValue)
            }
            if(res.Eqp_Attributes.CH_Out_Vlv_On_Off){
              setCH_Out_Vlv_On_Off(res.Eqp_Attributes.CH_Out_Vlv_On_Off.presentValue)
            }
            if(res.Eqp_Metrics.rh_cumulative){
              setRunHours(res.Eqp_Metrics.rh_cumulative)
            }
            if(selectedChId){
            api.floor.chillerGraphDataLast1Hr(selectedChId).then((response)=>{
              if(response.graphData.length){
                if(response.graphData[0]['CWH_ST']){
                    setCWH_ST(response.graphData[0]['CWH_ST'])
                }
                 if(response.graphData[0]['CWH_RT']){
                  setCWH_RT(response.graphData[0]['CWH_RT'])
               }
                 if(response.graphData[0]['CndW_HST']){
                  setCndW_HST(response.graphData[0]['CndW_HST'])
                }
                 if(response.graphData[0]['CndW_HRT']){
                  setCndW_HRT(response.graphData[0]['CndW_HRT'])
                }
                if(response.graphData[0]['VFD_Ph_Cur']){
                  setVFD_Ph_Cur(response.graphData[0]['VFD_Ph_Cur'])
                }
              }
            }).catch((error)=>{
              setOpenerr(true)
              if(error.response.data.message){
                setErrmsg(error.response.data.message)
                }else{
                  setErrmsg('')
                }     
               })
              }
          }
          })
        }).catch((error)=>{
          setOpenerr(true)
          if(error.response){
            setErrmsg(error.response.data.message)
            }else{
              setErrmsg('')
            }
          })
      }
      
    }, 5000);
    return () => clearInterval(timer);
  },[selectedChId])

  const onChange = (newValue) => {
    setChillerOnOff(newValue)
    let id= selectedChId;
    let action= newValue == 0? "TURN_OFF_CHILLER":"TURN_ON_CHILLER";
    let ss_type= "NONGL_SS_CHILLER";
    let req={
        id,
        action,
        ss_type
    }
    api.floor.cpmOnOffControl(req).then((response)=>{
      setChillerOnOff(response.startsWith('Working with a Scenario')?(newValue == 0?1:0):newValue)   
      toast({
        type:  response.startsWith('Working with a Scenario')?"error":"success",
        icon:  response.startsWith('Working with a Scenario')?"exclamation triangle":"check circle",
        title: response.startsWith('Working with a Scenario')?"Error":"Success",
        description: response,
        time: 2000,
      });

    })
    .catch((err)=>{
      setOpenerr(true)
      setErrmsg(err)   
    })
  };

  return (
    <div className={classes.root}>
       <Snackbar open={openerr} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert style={{ cursor: "pointer" }} severity="error" variant="filled" onClose={handleerrorclose}>
          {errmsg}
        </Alert>
      </Snackbar>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1} style={{marginTop:'-1.5h'}}>
            {/* Left part */}
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <FormControl
                              variant="filled"
                              size="large"
                              className={` ${classes.select} ${classes.formControl}`}
                              style={{
                                width: "max-content",
                                minWidth: "100%",
                                backgroundColor: "#eeeef5",
                                fontFamily: "Arial"
                              }}
                            >
                              <Select
                                labelId="filled-hidden-label-small"
                                id="demo-simple-select-outlined"
                                label="Chiller"
                                // className={classes.select}
                                value={selectedChName}
                                style={{
                                  fontWeight: "bold",
                                  height: "6vh",
                                  borderRadius: '0.8vw',
                                  fontFamily: "Arial"
                                }}
                                disableUnderline
                              >

                                {Object.values(allchillerData).map((_item) => (
                                  <MenuItem
                                    key={_item.id}
                                    value={_item.name}
                                    onClick={() => handleChillerChange(_item.name, _item.id)}
                                  >
                                    {_item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                        </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Card className={classes.paper} style={{borderRadius:"6px",boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",backgroundColor:"#fcfafa"}}>
                            <Map
                                              ref={mapRef}
                                              doubleClickZoom={false}
                                              zoomControl={false}
                                              dragging={false}
                                              scrollWheelZoom={false}
                                              crs={Leaflet.CRS.Simple}
                                              center={[0, 0]}
                                              attributionControl={false}
                                              // bounds={[[0, 0], [600, 730]]}
                                              bounds={[[0, 0], [420, 600]]}
                                              className={"floor-map"}
                                              style={{backgroundColor:"white",height: "48vh"}}
                                              onClick={(e) => { console.log({ x: e.latlng.lat, y: e.latlng.lng }) }}
                                            >
                                              <ImageOverlay
                                                interactive
                                                // url={'https://localhost/' + image + '.png'}
                                                url={ChillerImg}
                                                // bounds={[[50, 15], [600, 730]]}
                                                // bounds={[[100, -8], [525, 640]]}
                                                bounds={[[0, 0], [420, 590]]}
                                              />
                                          <Marker position={[236.20,92.06]} icon={iconDevice1}>
                                            <Tooltip direction="top" permanent>
                                              <div>
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    whiteSpace: "normal",fontFamily:"Arial"
                                                  }}
                                                >
                                                CW in
                                                </span>
                                                <br />
                                                <div
                                                  style={{
                                                    backgroundColor: "grey",
                                                    height: "18px",
                                                    width: "59px",
                                                    color: " #ffffff",
                                                    fontWeight: "500",
                                                    fontSize: "15px",
                                                    "letter-spacing": "9px",fontFamily:"Arial"
                                                  }}
                                                >
                                                {formatter.format(chWIn)}
                                                </div>
                                              </div>
                                            </Tooltip>
                                          </Marker>
                                          <Marker position={[237.08,483.28]} icon={iconDevice1}>
                                            <Tooltip direction="top" permanent>
                                              <div>
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    whiteSpace: "normal",fontFamily:"Arial"
                                                  }}
                                                >
                                                CW out
                                                </span>
                                                <br />
                                                <div
                                                  style={{
                                                    backgroundColor: "grey",
                                                    height: "18px",
                                                    width: "59px",
                                                    color: " #ffffff",
                                                    fontWeight: "500",
                                                    fontSize: "15px",
                                                    "letter-spacing": "9px",fontFamily:"Arial"
                                                  }}
                                                >
                                                {formatter.format(chWOut)}
                                                </div>
                                              </div>
                                            </Tooltip>
                                          </Marker>              
                                          <Marker position={[186.29,113.20]} icon={iconDevice1}>
                                            <Tooltip direction="bottom" permanent>
                                              <div>
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    whiteSpace: "normal",fontFamily:"Arial"
                                                  }}
                                                >
                                                CW
                                                </span>
                                                <br />
                                                <div
                                                  style={{
                                                    backgroundColor: "grey",
                                                    height: "18px",
                                                    width: "59px",
                                                    color: " #ffffff",
                                                    fontWeight: "500",
                                                    fontSize: "15px",
                                                    "letter-spacing": "9px",fontFamily:"Arial"
                                                  }}
                                                >
                                                N/A
                                                </div>
                                              </div>
                                            </Tooltip>
                                          </Marker>
                                          <Marker position={[170.32,476.28]} icon={iconDevice1}>
                                            <Tooltip direction="bottom" permanent>
                                              <div>
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    whiteSpace: "nowrap",fontFamily:"Arial"
                                                  }}
                                                >
                                                Valve status
                                                </span>
                                                <br />
                                                <div
                                                  style={{
                                                    backgroundColor: "grey",
                                                    height: "18px",
                                                    width: "62px",
                                                    color: " #ffffff",
                                                    fontWeight: "500",
                                                    fontSize: "15px",
                                                    "letter-spacing": "9px",fontFamily:"Arial"
                                                  }}
                                                >
                                                N/A
                                                </div>
                                              </div>
                                            </Tooltip>
                                          </Marker>
                            </Map>
                    </Card>
                </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1} style={{marginTop:"1vh"}}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Box className={classes.graphpaper}>
                          <div style={{fontWeight:'bold',color:'black'}} className={classes.CardHeadFont}>Chilled Water Temperature</div>
                            <div style={{marginTop:'2vh'}}>
                                   <TimeS
                                          name='Chilled Water Temperature'
                                          data={CWH_ST} data2={CWH_RT} 
                                      style={{ width: "100%", height: "50%" }}
                                    />
                              </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Box className={classes.graphpaper}>
                        <div style={{fontWeight:'bold',color:'black'}}  className={classes.CardHeadFont}>Condenser Water Temperature</div>
                                  <div style={{marginTop:'2vh'}}>
                                  <TimeS
                                        name='Condenser Water Temperature'
                                        data={CndW_HST} data2={CndW_HRT}
                                      style={{ width: "100%", height: "50%" }}
                                    /></div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Box className={classes.graphpaper}>
                          <div style={{fontWeight:'bold',color:'black'}}  className={classes.CardHeadFont}>VFD Phase Current</div>
                              <div style={{marginTop:'2vh'}}>
                                 <TimeSeriesChart
                                style={{ width: "100%", height: "50%" }}
                                data={VFD_Ph_Cur}
                                param='VFD_Ph_Cur'
                              />
                              </div>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {/* Right part */}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Grid container item xs={12} spacing={1} direction="column">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Paper style={{maxWidth:"100%",color: "white",backgroundColor: "#0123b4",borderRadius: "10px",height:"6vh",display: "flex", justifyContent: "flex-start", paddingLeft: "16px",alignItems: "center"}}  className={classes.CardHeadFont}>
                        <Typography style={{ color: "#ffffff",fontFamily:"Arial",fontSize:"2vh",textAlign: "left"}}>Chiller Controls</Typography>
                      </Paper>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Card className={classes.paper} style={{marginTop:"0vh",height:'29.3vh'}}>
                            <Grid container spacing={1}>
                               <Grid  container item xs={12} style={{marginTop:'2vh',textAlign:'left'}} 
                                          direction="row"  alignItems="center" justify="flex-start" 
                                          >  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                <div className={classes.CardHeadFont}>Chiller</div>
                                              </Grid>
                                              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>     
                                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                              <div  style={{pointerEvents:CPM_Status == "true"?"none":"", opacity:CPM_Status == "true"?"0.4":""}}
                                              className={classes.switchselector}>
                                              <SwitchSelector
                                                options={options}
                                                // initialSelectedIndex={initialSelectedIndex}
                                                onChange={onChange}
                                                forcedSelectedIndex={chillerOnOff}
                                                backgroundColor={"rgba(0, 0, 0, 0.04)"}
                                                fontColor={"#000"}
                                                selectedFontColor={"#000"}
                                                optionBorderRadius={5}
                                                wrapperBorderRadius={7}
                                                fontSize={8}
                                                /></div>
                                              </Grid>
                                </Grid>
                                <Grid  container item xs={12} style={{textAlign:'left'}} 
                                          direction="row"  alignItems="center" justify="flex-start" 
                                          >  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                <div className={classes.CardHeadFont}>Chiller Mode</div>
                                              </Grid>
                                              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>     
                                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                              <Paper style={{height:'3.5vh',width:"8vh",borderRadius:"2vh",backgroundColor:"#0123b4",color:"white",textAlign:"center", display: 'flex',alignItems: 'center', justifyContent: 'center'}}>{CH_AM_SS == 1?"Auto":CH_AM_SS == 0?"Manual":"undefined"}</Paper>
                                              </Grid>
                                </Grid>
                                <Grid  container item xs={12} style={{textAlign:'left'}} 
                                          direction="row"  alignItems="center" justify="flex-start" 
                                          >  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                <div className={classes.CardHeadFont}>Chiller Water Supply Temperature SP </div>
                                              </Grid>
                                              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>     
                                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                              <Paper style={{height:'3.5vh',width:"8vh",borderRadius:"2vh",backgroundColor:"#0123b4",color:"white",textAlign:"center", display: 'flex',alignItems: 'center', justifyContent: 'center'}}>{CWH_ST_SP+"°C"}</Paper>
                                              </Grid>
                                </Grid>
                                <Grid  container item xs={12} style={{textAlign:'left'}} 
                                          direction="row"  alignItems="center" justify="flex-start" 
                                          >  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                <div className={classes.CardHeadFont} style={{marginTop:'0.5vh'}}>Valve</div>
                                              </Grid>
                                              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>     
                                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                              <Paper style={{height:'3.5vh',width:"8vh",borderRadius:"2vh",backgroundColor:"#0123b4",color:"white",textAlign:"center", display: 'flex',alignItems: 'center', justifyContent: 'center'}}>{CH_Out_Vlv_On_Off}</Paper>
                                              </Grid>
                                </Grid>
                                <Grid  container item xs={12} style={{textAlign:'left'}} 
                                          direction="row"  alignItems="center" justify="flex-start" 
                                          >  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                              <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                  <FormControl
                                                    variant="filled"
                                                    size="large"
                                                    className={`${classes.select} ${classes.formControl}`}
                                                    style={{
                                                      width: "max-content",
                                                      minWidth: "85%",
                                                      backgroundColor: "#eeeef5",
                                                      fontFamily: "Arial"
                                                    }}
                                                  >
                                                    <Select
                                                      labelId="filled-hidden-label-small"
                                                      id="demo-simple-select-outlined"
                                                      label="Chiller"
                                                      value={selectedChAlarm.message}
                                                      style={{
                                                        fontWeight: "bold",
                                                        height: "4vh",
                                                        borderRadius: '0.8vw',
                                                        fontFamily: "Arial"
                                                      }}
                                                      disableUnderline
                                                    >
                                                      {chillerAlarm.map((_item) => (
                                                        <MenuItem
                                                        
                                                          key={_item.id}
                                                          value={_item.alarm}
                                                          onClick={() => handleChillerAlarmChange(_item.alarm, _item.id)}
                                                        >
                                                          {_item.Todisplay}
                                                        </MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                              </Grid>
                                              {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>      */}
                                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                              {disable ? 
                                                <Paper style={{height:'3.5vh',width:"8vh",borderRadius:"2vh",backgroundColor:"lightgrey",color:"black",textAlign:"center", display: 'flex',alignItems: 'center', justifyContent: 'center',pointerEvents: "none", opacity: "0.4"}}>
                                                <div onClick={()=>handleSubmitAlarmClick(selectedChAlarm)}>submit</div></Paper>
                                                :
                                                <Paper style={{height:'3.5vh',width:"8vh",borderRadius:"2vh",backgroundColor:"lightgrey",color:"black",textAlign:"center", display: 'flex',alignItems: 'center', justifyContent: 'center',cursor:"pointer"}}>
                                                <div onClick={()=>handleSubmitAlarmClick(selectedChAlarm)}>submit</div></Paper>
                                                }
                                              </Grid>
                                </Grid>
                                {/* <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    style={{ marginTop: "2vh", marginLeft: "-1%" }}
                                  >
                                    <CalendarToday color="primary" />
                                    <ButtonBase
                                      style={{ color: "#3f51b5",fontWeight:"bolder"  }}
                                      // onClick={() => handleLocationClick()}
                                    >
                                      Scheduler
                                    </ButtonBase>
                                </Grid> */}
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Paper style={{maxWidth:"100%",color: "white",backgroundColor: " #0123b4",borderRadius: "6px",height:"6vh",display: "flex", justifyContent: "flex-start", paddingLeft: "16px",alignItems: "center"}}>
                        <Typography style={{ color: "#ffffff",fontFamily:"Arial",fontSize:"2vh",textAlign: "left"}}>Chiller Parameters</Typography>
                      </Paper>
                    </Grid>   
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Card
                          className={classes.paper}
                          style={{
                            marginTop:'0.5vh',
                            // boxShadow:"0 4px 10px 2px rgba(0, 0, 0, 0.1)",
                            // backgroundColor: " #ffffff",
                            borderRadius: "6px",
                            height: "40.6vh",
                          }}
                        >
                                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1}>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                              <Card className={classes.semicircularbar} style={{fontWeight:'bold',color:'black'}}>
                                              <div className={classes.CardHeadFont}>IKW/TR</div>
                                              <div className={classes.semicircularbarcomp}>
                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage='50' />
                                              </div>
                                              <div style={{ marginTop: '-2.1vh', fontSize: '13px',marginLeft:'0.7vh' }}>50%</div>
                                              </Card>
                                          </Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                              <Card className={classes.semicircularbar} style={{fontWeight:'bold',color:'black',whiteSpace:'nowrap'}}>
                                                <div className={classes.CardHeadFont}>Chiller Loading</div>
                                                <div className={classes.semicircularbarcomp}>
                                                    <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage='50' />
                                                </div>
                                                <div style={{ marginTop: '-2.1vh', fontSize: '13px',marginLeft:'0.7vh' }}>50%</div>
                                              </Card>
                                          </Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                                <Card className={classes.semicircularbar} style={{fontWeight:'bold',color:'black'}}>
                                                  <div className={classes.CardHeadFont}>Run Hours</div>
                                                  <div style={{display:'flex',justifyContent:'center',textAlign:'center',color:'#0123B4',fontSize:'3.5vh',marginTop:'1vh'}}>
                                                    {RunHours?formatter.format((formatter.format(RunHours)/60))+'Hrs':'N/A'}
                                                    {/* 400 */}
                                                      {/* <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage='50' /> */}
                                                  </div>
                                                  {/* <div style={{ marginTop: '-2.1vh', fontSize: '13px',marginLeft:'0.7vh' }}>50%</div> */}
                                                </Card>
                                          </Grid>
                                </Grid>
                                <Grid container xs={12} style={{marginTop:'1.5vh',fontWeight:'bold',color:'black'}}>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'-0.5vh'}}>
                                              <div className={classes.CardHeadFont}>Amps(A)</div>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{marginTop:'-0.5vh'}}>
                                            <ProgressBar 
                                            completed={60} 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={false}/>
                                          </Grid>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                              <div className={classes.CardHeadFont}>Volts -R(V)</div>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{marginTop:'-0.5vh'}}>
                                            <ProgressBar 
                                            completed={60} 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={false}/>
                                          </Grid>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'-0.5vh'}}>
                                              <div className={classes.CardHeadFont}>Volts -Y(V)</div>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{marginTop:'-0.5vh'}}>
                                            <ProgressBar 
                                            completed={60} 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={false}/>
                                          </Grid>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'-0.5vh'}}>
                                              <div className={classes.CardHeadFont}>Volts -B(V)</div>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{marginTop:'-0.5vh'}}>
                                            <ProgressBar 
                                            completed={60} 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={false}/>
                                          </Grid>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'-0.5vh'}}>
                                              <div className={classes.CardHeadFont}>VFD(Hz)</div>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{marginTop:'-0.5vh'}}>
                                            <ProgressBar 
                                            completed={60} 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={false}/>
                                          </Grid>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                    </Grid>
                                </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
      <SemanticToastContainer position='top-center' />
    </div>
  )
}
