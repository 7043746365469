import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {Card} from '@material-ui/core';


ChartJS.register(...registerables);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  faultCard:{
    width:"100%",
    height:"100%"
   }
}));

const FaultTrend = ({ trendData }) => {
  const classes = useStyles();
  const [selectedDevices, setSelectedDevices] = useState([trendData[0].Devicename]);

  const handleChange = (event) => {
    setSelectedDevices(event.target.value);
  };

  const getBorderColor = (deviceName) => {
    switch (deviceName) {
      case 'AHU-1':
        return 'rgba(255, 99, 132, 1)';
      case 'AHU-2':
        return 'rgba(54, 162, 235, 1)';
      case 'AHU-3':
        return 'rgba(255, 206, 86, 1)';
      case 'Chiller-1':
        return 'rgba(75, 192, 192, 1)';
      case 'Chiller-2':
        return 'rgba(153, 102, 255, 1)';
      case 'Chiller-3':
        return 'rgba(255, 159, 64, 1)';
      default:
        return 'rgba(75, 192, 192, 1)';
    }
  };

  const trendDatasets = selectedDevices.map((deviceName) => {
    const deviceData = trendData.find(device => device.Devicename === deviceName);
    return {
      label: deviceData.Devicename,
      data: deviceData.counts,
      fill: false,
      borderColor: getBorderColor(deviceData.Devicename),
    };
  });

  const trendDataConfig = {
    labels: trendData[0].timestamps,
    datasets: trendDatasets,
  };

  const deviceNames = trendData.map(device => device.Devicename);

  return (
    <div>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-name-label">Device Name</InputLabel>
          <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            multiple
            value={selectedDevices}
            onChange={handleChange}
            input={<Input />}
          >
            {deviceNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <h2>Fault Counts Trend Over Time</h2>
        <Line data={trendDataConfig} height={200} width={400} />
      </div>
    </div>
  );
};

const App = () => {
  const classes = useStyles();
  // Sample trend data
  const trendData = [
    {
      Devicename: "AHU-1",
      timestamps: ['01-01-2024', '01-02-2024', '01-03-2024', '01-04-2024', '01-05-2024'],
      counts: [1, 2, 4, 3, 5],
    },
    {
      Devicename: "AHU-2",
      timestamps: ['01-01-2024', '01-02-2024', '01-03-2024', '01-04-2024', '01-05-2024'],
      counts: [2, 5, 7, 3, 9],
    },
    {
      Devicename: "AHU-3",
      timestamps: ['01-01-2024', '01-02-2024', '01-03-2024', '01-04-2024', '01-05-2024'],
      counts: [6, 2, 9, 8, 10],
    },
    {
      Devicename: "Chiller-1",
      timestamps: ['01-01-2024', '01-02-2024', '01-03-2024', '01-04-2024', '01-05-2024'],
      counts: [6, 2, 9, 8, 10],
    },
    {
      Devicename: "Chiller-2",
      timestamps: ['01-01-2024', '01-02-2024', '01-03-2024', '01-04-2024', '01-05-2024'],
      counts: [2, 1, 7, 4, 1],
    },
    {
      Devicename: "Chiller-3",
      timestamps: ['01-01-2024', '01-02-2024', '01-03-2024', '01-04-2024', '01-05-2024'],
      counts: [3, 6, 4, 3, 2],
    },
  ];

  return (
    <div>
      <Grid container xs={12}>
        <Card className={classes.faultCard}>
    <FaultTrend trendData={trendData} />
    </Card>
    </Grid>
    </div>
  );
};

export default App;
